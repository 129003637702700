import React from 'react'
import cardSvg from '../../assets/svg-cards.svg'
import PropTypes from 'prop-types'

function CardBackSvg({ x = 0, y = 0, rotate = 0 }) {
  return (
    <use
      x={x}
      y={y}
      xlinkHref={`${cardSvg}#back`}
      fill="#6C88AB"
      transform={`rotate(${rotate})`}
    />
  )
}

CardBackSvg.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  rotate: PropTypes.number
}

export default CardBackSvg
