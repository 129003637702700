import React from 'react'
import { Link } from 'react-router-dom'
import GoogleButton from 'react-google-button'
import Paper from '@mui/material/Paper'
import { useFirebase } from 'react-redux-firebase'
import { makeStyles } from '@mui/styles'
import { LOGIN_PATH } from 'constants/paths'
import { useNotifications } from 'modules/notification'
import SignupForm from '../SignupForm'
import styles from './SignupPage.styles'

const useStyles = makeStyles(styles)

function SignupPage() {
  const classes = useStyles()
  const { showError } = useNotifications()
  const firebase = useFirebase()

  function onSubmitFail(formErrs, dispatch, err) {
    showError(formErrs ? 'Form Invalid' : err.message || 'Error')
  }

  function googleLogin() {
    return firebase
      .login({ provider: 'google', type: 'popup' })
      .catch((err) => showError(err.message))
  }

  function emailSignup(creds) {
    return firebase
      .createUser(creds, {
        email: creds.email,
        displayName: creds.username
      })
      .catch((err) => showError(err.message))
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.panel}>
        {/* eslint-disable-next-line react/jsx-no-bind */}
        <SignupForm onSubmit={emailSignup} onSubmitFail={onSubmitFail} />
      </Paper>
      <div className={classes.orLabel}>of</div>
      <div className={classes.providers}>
        {/* eslint-disable-next-line react/jsx-no-bind */}
        <GoogleButton onClick={googleLogin} data-test="google-auth-button" />
      </div>
      <div className={classes.login}>
        <span className={classes.loginLabel}>Al een account?</span>
        <Link className={classes.loginLink} to={LOGIN_PATH}>
          Inloggen
        </Link>
      </div>
    </div>
  )
}

export default SignupPage
