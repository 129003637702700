export default (theme) => ({
  root: {
    padding: theme.spacing(2),
    minWidth: '450px'
  },
  inputs: {
    ...theme.flexColumnCenter
  },
  buttons: {
    ...theme.flexColumnCenter
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: '200px'
  }
})
