import React from 'react'
import Navbar from 'containers/Navbar'
import { Notifications } from 'modules/notification'
import Footer from '../../containers/Footer'
import { Outlet, useNavigation } from 'react-router-dom'
import LoadingSpinner from '../../components/LoadingSpinner'

function CoreLayout() {
  const navigation = useNavigation()
  return (
    <div>
      {navigation.state === 'loading' && <LoadingSpinner />}
      <Navbar />
      <Outlet />
      <Notifications />
      <Footer />
    </div>
  )
}

export default CoreLayout
