export default (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'row',
    paddingTop: '1rem',
    marginBottom: '1rem'
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50%'
  },
  chatContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: '#FFF',
    padding: theme.spacing(4),
    maxWidth: '950px'
  },
  messagesContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: '40vh'
  },
  inlineDisplayName: {
    fontWeight: 'bold'
  },
  listItem: {
    padding: '0px !important'
  },
  date: {
    paddingRight: '3px'
  },
  smaller: {
    width: '90%'
  }
})
