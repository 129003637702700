import React, { useState } from 'react'
import { useFirebase } from 'react-redux-firebase'
import { useNavigate } from 'react-router-dom'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { makeStyles } from '@mui/styles'
import { ACCOUNT_PATH } from 'constants/paths'
import { useSelector } from 'react-redux'
import { LIST_PATH } from '../../constants/paths'

const useStyles = makeStyles(() => ({
  buttonRoot: {
    color: 'white'
  }
}))

function AccountMenu() {
  const classes = useStyles()
  const [anchorEl, setMenu] = useState(null)
  const navigate = useNavigate()
  const firebase = useFirebase()

  const profile = useSelector(({ firebase }) => firebase.profile)

  function closeAccountMenu() {
    setMenu(null)
  }
  function handleMenu(e) {
    setMenu(e.target)
  }
  async function handleLogout() {
    closeAccountMenu()
    // redirect to '/' handled by UserIsAuthenticated HOC
    await firebase.logout()
    window.location.href = LIST_PATH
  }
  function goToAccount() {
    closeAccountMenu()
    navigate(ACCOUNT_PATH)
  }

  return (
    <>
      <IconButton
        aria-owns={anchorEl ? 'menu-appbar' : null}
        aria-haspopup="true"
        onClick={(e) => handleMenu(e)}
        classes={{ root: classes.buttonRoot }}>
        <AccountCircle color="neutral" />
      </IconButton>
      {profile && profile.displayName}
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={() => closeAccountMenu()}>
        <MenuItem onClick={() => goToAccount()}>Mijn Account</MenuItem>
        <MenuItem onClick={() => handleLogout()}>Uitloggen</MenuItem>
      </Menu>
    </>
  )
}

export default AccountMenu
