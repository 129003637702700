import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import { validateEmail } from 'utils/form'
import styles from './LoginForm.styles'

const useStyles = makeStyles(styles)

function LoginForm({ onSubmit }) {
  const classes = useStyles()
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting, isValid }
  } = useForm({
    mode: 'onChange',
    nativeValidation: false
  })

  return (
    <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
      <TextField
        type="email"
        name="email"
        placeholder="Email"
        margin="normal"
        fullWidth
        {...register('email', {
          required: true,
          validate: validateEmail
        })}
        error={errors ? errors.email : ''}
        helperText={errors ? errors.email && 'Email must be valid' : ''}
      />
      <TextField
        type="password"
        name="password"
        placeholder="Wachtwoord"
        margin="normal"
        fullWidth
        {...register('password', {
          required: true
        })}
        error={errors ? errors.email : ''}
        helperText={errors ? errors.email && 'Password is required' : ''}
      />
      <div className={classes.submit}>
        <Button
          color="primary"
          type="submit"
          variant="contained"
          disabled={isSubmitting || !isValid}>
          {isSubmitting ? 'Loading' : 'Inloggen'}
        </Button>
      </div>
    </form>
  )
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

export default LoginForm
