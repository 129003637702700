import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import SnackbarContent from '@mui/material/SnackbarContent'
import CloseIcon from '@mui/icons-material/Close'
import { makeStyles } from '@mui/styles'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'
import green from '@mui/material/colors/green'
import amber from '@mui/material/colors/amber'
import WarningIcon from '@mui/icons-material/Warning'
import useNotifications from './useNotifications'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
}

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    // backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing()
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
}))

function Notifications() {
  const classes = useStyles()
  const { allIds, byId, dismissNotification } = useNotifications()
  // Only render if notifications exist
  if (!allIds || !Object.keys(allIds).length) {
    return null
  }

  return (
    <div>
      {allIds.map((id) => {
        const Icon = variantIcon[byId[id].type] || variantIcon.info
        function dismissNotificationById() {
          return dismissNotification(id)
        }
        return (
          <Snackbar
            key={id}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open
            className={classes.snackbar}>
            <SnackbarContent
              className={classes[byId[id].type] || classes.info}
              aria-describedby="client-snackbar"
              message={
                <span
                  id="client-snackbar"
                  data-test="notification-message"
                  className={classes.message}>
                  <Icon className={classes.icon} />
                  {byId[id].message}
                </span>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={() => dismissNotificationById()}>
                  <CloseIcon className={classes.icon} />
                </IconButton>
              ]}
            />
          </Snackbar>
        )
      })}
    </div>
  )
}

export default Notifications
