export default (theme) => ({
  root: {
    ...theme.flexColumnCenter,
    padding: theme.spacing(4),
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '1080px',
    margin: '0 auto'
  },
  tiles: {
    display: 'flex',
    flexWrap: 'wrap',
    '-webkit-flex-flow': 'row wrap',
    width: '100%',
    maxWidth: '950px',
    justifyContent: 'center',
    textAlign: 'center'
  }
})
