import React from 'react'
import PropTypes from 'prop-types'
import ContentAddCircle from '@mui/icons-material/AddCircle'
import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles'
import styles from './NewProjectTile.styles'

const useStyles = makeStyles(styles)

function NewProjectTile({ onClick }) {
  const classes = useStyles()

  return (
    <Button
      className={classes.root}
      onClick={onClick}
      color="secondary"
      variant="contained">
      <ContentAddCircle className={classes.newIcon} />
      <strong>Nieuw spel</strong>
    </Button>
  )
}

NewProjectTile.propTypes = {
  onClick: PropTypes.func
}

export default NewProjectTile
