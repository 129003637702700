import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import styles from '../Game/Game.styles'

const useStyles = makeStyles(styles)

function Score({ player, mate, opponent1, opponent2, team1score, team2score }) {
  const classes = useStyles()

  const scores = []
  scores.team_1_score = team1score
  scores.team_2_score = team2score
  const widderTeamId = player.team
  const otherTeamId = widderTeamId === 1 ? 2 : 1

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <strong>Widder</strong> <br />
              <small>{player.displayName}</small> /{' '}
              <small>{mate.displayName}</small>
            </TableCell>
            <TableCell align="center">
              <strong>Zidder</strong> <br />
              <small>{opponent1.displayName}</small> /{' '}
              <small>{opponent2.displayName}</small>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center">
              <strong>{scores[`team_${widderTeamId}_score`]}</strong>
            </TableCell>
            <TableCell align="center">
              <strong>{scores[`team_${otherTeamId}_score`]}</strong>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

Score.propTypes = {
  player: PropTypes.object,
  mate: PropTypes.object,
  opponent1: PropTypes.object,
  opponent2: PropTypes.object,
  team1score: PropTypes.object,
  team2score: PropTypes.object
}

export default Score
