import React from 'react'
import { makeStyles } from '@mui/styles'
import styles from './Footer.styles'

const useStyles = makeStyles(styles)

function Footer() {
  const classes = useStyles()
  return (
    <section>
      <div className={classes.root}>
        <small>
          Versie 0.0.0.66 - Laatste update 25/04/2023 -
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.instagram.com/manillen.eu/"
            title="Manillen.eu instagram">
            {' '}
            @manillen.eu
          </a>
        </small>
      </div>
    </section>
  )
}

export default Footer
