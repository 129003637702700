export default (theme) => ({
  gridItem: {
    textAlign: 'center',
    marginTop: theme.spacing(5)
  },
  pane: {
    ...theme.flexColumnCenter,
    marginTop: theme.spacing(5),
    maxWidth: '950px',
    margin: '0 auto',
    justifyContent: 'space-around',
    padding: theme.spacing(6)
  }
})
