import React, { useState } from 'react'
import { makeStyles, withStyles } from '@mui/styles'
import styles from './Game.styles'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import GameField from '../GameField'
import HandBack from '../Hand/HandBack'
import Hand from '../Hand'
import Typography from '@mui/material/Typography'
import { isLoaded, useFirebase, useFirebaseConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Spade from 'static/cards/spade.svg'
import Diamond from 'static/cards/diamond.svg'
import Heart from 'static/cards/heart.svg'
import Club from 'static/cards/club.svg'
import LoadingSpinner from 'components/LoadingSpinner'
import NewTrumpDialog from '../NewTrumpDialog'
import FollowTrumpDialog from '../FollowTrumpDialog'
import Avatar from '@mui/material/Avatar'
import Chip from '@mui/material/Chip'
import PauseHandDialog from '../PauseHandDialog/PauseHandDialog'
import { isCardPlayable } from './isCardPlayable'
import { isAnyCardPlayable } from './isAnyCardPlayable'
import ScoreBoard from '../ScoreBoard/ScoreBoard'
import Score from '../Score/Score'
import { assessPlayGround } from './utils/assessPlayGround'
import { assessPlayerCards } from './utils/assessPlayerCards'
import { assessGameInfo } from './utils/assessGameInfo'
import ScorePopUp from '../ScorePopup/ScorePopup'
import Tooltip from '@mui/material/Tooltip'
import CONFUSED from 'static/CONFUSED.png'
import LOL from 'static/LOL.jpg'
import OK from 'static/OK.jpg'
import SAD from 'static/SAD.jpg'
import { GAMES_PATH } from '../../../../constants/paths'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Chat from '../../../../modules/chat'

const useStyles = makeStyles(styles)

/**
 * @return {number}
 */
function Game() {
  const classes = useStyles()
  const [value, setValue] = useState(0)
  const { gameId } = useParams()
  const firebase = useFirebase()
  const auth = useSelector(({ firebase: { auth } }) => auth)
  const [showScore, toggleScoreAction] = useState(false)
  const stickerOptions = {
    1: CONFUSED,
    2: LOL,
    3: OK,
    4: SAD
  }

  useFirebaseConnect([
    {
      path: `projects/${gameId}`,
      storeAs: 'game'
    }
  ])
  const game = useSelector(
    ({
      firebase: {
        data: { game }
      }
    }) => game
  )
  useFirebaseConnect([
    {
      path: `projects/${gameId}/game/hands`,
      queryParams: ['limitToLast=1'],
      storeAs: 'hand'
    }
  ])
  const hand = useSelector(
    ({
      firebase: {
        data: { hand }
      }
    }) => hand
  )

  if (!isLoaded(game)) {
    return <LoadingSpinner />
  }
  if (!isLoaded(hand)) {
    return <LoadingSpinner />
  }
  if (isLoaded(game) && isLoaded(hand) && hand === null) {
    firebase.push(`projects/${gameId}/game/logs/`, {
      message: 'SHOCK GAME, SOMETHING WENT WRONG'
    })
    window.location.href = `${GAMES_PATH}/${gameId}`
  }

  const gameInfo = assessGameInfo(hand, game, auth.uid)
  function getPlayerHandObject(id) {
    return hand[`${gameInfo.handId}`][`${id}`]
  }

  const playerCardsInfo = assessPlayerCards(
    gameInfo.playerHand,
    gameInfo.suitToFollow,
    gameInfo.currentHandTrump
  )
  const playgroundInfo = assessPlayGround(
    game,
    gameInfo.currentHand,
    gameInfo.previousHand,
    gameInfo.round,
    auth.uid
  )
  const opponent1Cards = playgroundInfo.opponent1Game.count
  const opponent2Cards = playgroundInfo.opponent2Game.count
  const mateCards = playgroundInfo.mateGame.count
  // FIRST YOU PUT MYFOLLOWTRUMP ON DEFINED, IF I AM AN OPPONENT MYSELF, I ONLY WANT TO SEE WINDOW IF I HAVEN'T CHOSEN TRUMP!
  let myFollowTrump = true
  if (playgroundInfo.isOpponent) {
    myFollowTrump = getPlayerHandObject(
      playgroundInfo.handPlayerMap.me
    ).followTrump
  }
  const stickerObject = {
    ownSticker: null,
    opponent2Sticker: null,
    mateSticker: null,
    opponent1Sticker: null
  }

  if (gameInfo.currentHand.chat && gameInfo.currentHand.chat !== 'undefined') {
    Object.entries(gameInfo.currentHand.chat).map((sticker) => {
      if (sticker[1].userId === playgroundInfo.handPlayerMap.me) {
        return (stickerObject.ownSticker = parseInt(sticker[1].message))
      }
      if (sticker[1].displayName === playgroundInfo.opponent2.displayName) {
        return (stickerObject.opponent2Sticker = parseInt(sticker[1].message))
      }
      if (sticker[1].displayName === playgroundInfo.mate.displayName) {
        return (stickerObject.mateSticker = parseInt(sticker[1].message))
      }
      if (sticker[1].displayName === playgroundInfo.opponent1.displayName) {
        return (stickerObject.opponent1Sticker = parseInt(sticker[1].message))
      }
    })

    setTimeout(function () {
      return firebase
        .database()
        .ref(`projects/${gameId}/game/hands/${gameInfo.handId}/chat`)
        .remove()
    }, 4000)
  }

  const followTrump01 = getPlayerHandObject(
    playgroundInfo.handPlayerMap.closest_opponent
  ).followTrump
  const followTrump02 = getPlayerHandObject(
    playgroundInfo.handPlayerMap.opponent2
  ).followTrump
  const gameFollowTrump =
    typeof followTrump01 !== 'undefined' && typeof followTrump02 !== 'undefined'
      ? followTrump01 + followTrump02
      : 'undefined'

  const addTrump = (event) => {
    return firebase
      .update(`projects/${gameId}/game/hands/${gameInfo.handId}`, {
        trump: event.target.value
      })
      .then(() => {
        firebase.push(`projects/${gameId}/game/hands/${gameInfo.handId}/logs`, {
          message: `TRUMP CHOSEN (${event.target.value}) BY ${auth.uid}`
        })
      })
  }
  const followTrump = (event) => {
    return firebase
      .update(`projects/${gameId}/game/hands/${gameInfo.handId}/${auth.uid}`, {
        followTrump: JSON.parse(event.target.value)
      })
      .then(() => {
        firebase.push(`projects/${gameId}/game/hands/${gameInfo.handId}/logs`, {
          message: `CHOSEN IF FOLLOWED (${event.target.value}) BY ${auth.uid}`
        })
      })
  }

  const isAnyCardPlayableVar = isAnyCardPlayable(
    playerCardsInfo.playerCards,
    playgroundInfo.opponent1Card,
    playgroundInfo.mateCard,
    playgroundInfo.opponent2Card,
    gameInfo.currentHandSuit,
    playerCardsInfo.suitToFollow,
    gameInfo.currentHandTrump
  )

  function playCard(card, cardKey) {
    if (gameInfo.playerAtHandId !== auth.uid) {
      return
    }
    // IF IS NOT FIRST ROUND OR I AM NOT ALLOWED TO PLAY THIS CARD
    if (playerCardsInfo.playableCards.length !== 0) {
      if (
        !isCardPlayable(
          card,
          playgroundInfo.opponent1Card,
          playgroundInfo.mateCard,
          playgroundInfo.opponent2Card,
          gameInfo.currentHandSuit,
          playerCardsInfo.suitToFollow,
          gameInfo.currentHandTrump,
          isAnyCardPlayableVar
        )
      ) {
        return
      }
    }
    // PREVENT CLICKING 2 TIMES
    if (gameInfo.round[`${auth.uid}`]) {
      return
    }
    firebase
      .update(
        `projects/${gameId}/game/hands/${gameInfo.handId}/playground/${auth.uid}`,
        {
          suit: card.suit,
          rank: card.rank
        }
      )
      .then(() => {
        firebase
          .remove(
            `projects/${gameId}/game/hands/${gameInfo.handId}/${auth.uid}/cards/${cardKey}`
          )
          .then(() => {
            firebase
              .update(
                `projects/${gameId}/game/hands/${gameInfo.handId}/${auth.uid}`,
                {
                  count: playerCardsInfo.playerCards.length - 1
                }
              )
              .then(() => {
                firebase.push(
                  `projects/${gameId}/game/hands/${gameInfo.handId}/logs`,
                  {
                    message: `CARD ${card.suit}, ${card.rank} PLAYED BY ${auth.uid}`
                  }
                )
              })
          })
          .catch((err) => {
            console.error('Error:', err) // eslint-disable-line no-console
            return Promise.reject(err)
          })
      })
  }

  function showSticker(sticker) {
    firebase.push(`projects/${gameId}/game/hands/${gameInfo.handId}/chat`, {
      userId: auth.uid,
      displayName: playgroundInfo.player.displayName,
      message: sticker,
      createdAt: firebase.database.ServerValue.TIMESTAMP
    })
  }

  const gameIsPaused = game.game.state === 'PAUSED'
  function unPauseGame() {
    firebase.update(`projects/${gameId}/game`, {
      state: 'START'
    })
  }

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      maxWidth: 400,
      fontSize: theme.typography.pxToRem(12)
    }
  }))(Tooltip)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  return (
    <Grid container className={classes.root} justify="center">
      <ScoreBoard
        mate={playgroundInfo.mate}
        player={playgroundInfo.player}
        opponent1={playgroundInfo.opponent1}
        opponent2={playgroundInfo.opponent2}
        hands={game.game.hands}
        open={game.game.state === 'END' || showScore}
        team1score={
          typeof game.game.team_1_score !== 'undefined'
            ? game.game.team_1_score.score
            : 0
        }
        team2score={
          typeof game.game.team_2_score !== 'undefined'
            ? game.game.team_2_score.score
            : 0
        }
        endScore={game.endScore}
        hideScoreAction={() => toggleScoreAction(!showScore)}
      />
      <NewTrumpDialog
        handleChange={addTrump}
        open={!gameInfo.trump && gameInfo.isPlayerAtHand}
      />
      {/* eslint-disable-next-line react/jsx-no-bind */}
      <PauseHandDialog resumeGame={unPauseGame} open={gameIsPaused} />
      <FollowTrumpDialog
        trump={gameInfo.trump}
        handleChange={followTrump}
        open={
          gameInfo.trump &&
          typeof myFollowTrump === 'undefined' &&
          playgroundInfo.isOpponent
        }
      />
      <Grid item xs={9} lg={6} xl={5}>
        <div className={classes.container}>
          <div className={classes.horizontalOpponentHand}>
            {playgroundInfo.mate.displayName !==
            gameInfo.playerAtHand.displayName ? (
              <Chip
                avatar={<Avatar alt={playgroundInfo.mate.displayName} />}
                color="primary"
                label={playgroundInfo.mate.displayName}
                className={classes.displayName}
              />
            ) : (
              <Chip
                avatar={<Avatar alt={playgroundInfo.mate.displayName} />}
                label={playgroundInfo.mate.displayName}
                color="secondary"
                className={classes.displayName}
              />
            )}
            {stickerObject.mateSticker !== null && (
              <img
                width={90}
                height={90}
                src={stickerOptions[`${stickerObject.mateSticker}`]}
                style={{ position: 'absolute' }}
              />
            )}
            <HandBack amountOfCards={mateCards} maxAmountOfCards={8} />
          </div>
          <div className={classes.leftVerticalOpponentHand}>
            <div className={classes.verticalHandContainer}>
              {playgroundInfo.opponent1.displayName !==
              gameInfo.playerAtHand.displayName ? (
                <Chip
                  avatar={<Avatar alt={playgroundInfo.opponent1.displayName} />}
                  color="primary"
                  label={playgroundInfo.opponent1.displayName}
                  className={classes.verticalDisplayName}
                />
              ) : (
                <Chip
                  avatar={<Avatar alt={playgroundInfo.opponent1.displayName} />}
                  color="secondary"
                  label={playgroundInfo.opponent1.displayName}
                  className={classes.verticalDisplayName}
                />
              )}
              {stickerObject.opponent1Sticker !== null && (
                <img
                  width={90}
                  height={90}
                  src={stickerOptions[`${stickerObject.opponent1Sticker}`]}
                  style={{ position: 'absolute', bottom: '50%', left: '100px' }}
                />
              )}
              <HandBack
                vertical
                amountOfCards={opponent1Cards}
                maxAmountOfCards={8}
              />
            </div>
          </div>

          <div className={classes.gameField}>
            <GameField
              playerCard={gameInfo.round[auth.uid]}
              fellowPlayerCard={playgroundInfo.mateCard}
              opponent1Card={playgroundInfo.opponent1Card}
              opponent2Card={playgroundInfo.opponent2Card}
            />
          </div>

          <div className={classes.rightVerticalOpponentHand}>
            <div className={classes.verticalHandContainer}>
              {playgroundInfo.opponent2.displayName !==
              gameInfo.playerAtHand.displayName ? (
                <Chip
                  avatar={<Avatar alt={playgroundInfo.opponent2.displayName} />}
                  color="primary"
                  label={playgroundInfo.opponent2.displayName}
                  className={classes.verticalDisplayName}
                />
              ) : (
                <Chip
                  avatar={<Avatar alt={playgroundInfo.opponent2.displayName} />}
                  color="secondary"
                  label={playgroundInfo.opponent2.displayName}
                  className={classes.verticalDisplayName}
                />
              )}
              {stickerObject.opponent2Sticker !== null && (
                <img
                  width={90}
                  height={90}
                  src={stickerOptions[`${stickerObject.opponent2Sticker}`]}
                  style={{
                    position: 'absolute',
                    bottom: '50%',
                    right: '150px'
                  }}
                />
              )}
              <HandBack
                vertical
                amountOfCards={opponent2Cards}
                maxAmountOfCards={8}
              />
            </div>
          </div>

          {/* Player row */}
          <div className={classes.horizontalPlayerHand}>
            <Hand
              cards={playerCardsInfo.playerCards}
              maxAmountOfCards={8}
              pickCard={(card, cardKey) => playCard(card, cardKey)}
              suitToFollow={playerCardsInfo.suitToFollow}
              currentHandSuit={gameInfo.currentHandSuit}
              currentHandTrump={gameInfo.currentHandTrump}
              opponent1Card={playgroundInfo.opponent1Card}
              opponent2Card={playgroundInfo.opponent2Card}
              mateCard={playgroundInfo.mateCard}
              isAnyCardPlayable={isAnyCardPlayableVar}
              noblur={
                playgroundInfo.player.displayName !==
                  gameInfo.playerAtHand.displayName ||
                playerCardsInfo.playableCards.length === 0
              }
            />
            <HtmlTooltip
              arrow
              interactive
              title={
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {Object.entries(stickerOptions).map((sticker) => {
                    return (
                      <img
                        key={sticker[0]}
                        width={60}
                        height={60}
                        src={sticker[1]}
                        onClick={() => showSticker(sticker[0])}
                        className={classes.sticker}
                      />
                    )
                  })}
                </div>
              }>
              {playgroundInfo.player.displayName !==
              gameInfo.playerAtHand.displayName ? (
                <Chip
                  avatar={<Avatar alt={playgroundInfo.player.displayName} />}
                  color="default"
                  label={playgroundInfo.player.displayName}
                  className={classes.owDisplayName}
                />
              ) : (
                <Chip
                  avatar={<Avatar alt={playgroundInfo.player.displayName} />}
                  color="secondary"
                  label={playgroundInfo.player.displayName}
                  className={classes.owDisplayName}
                />
              )}
            </HtmlTooltip>
            {stickerObject.ownSticker !== null && (
              <img
                width={90}
                height={90}
                style={{ position: 'absolute', bottom: '25%' }}
                src={stickerOptions[`${stickerObject.ownSticker}`]}
              />
            )}
          </div>
        </div>
      </Grid>
      <Grid item xs={9} lg={4} xl={3} className={classes.gridItem}>
        <Paper className={classes.panel}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example">
            <Tab label="Game" {...a11yProps(0)} />
            <Tab label="Chat" {...a11yProps(1)} />
          </Tabs>

          <TabPanel value={value} index={0}>
            {!gameInfo.trump && !playgroundInfo.isPlayerAtHand ? (
              <div className={classes.title}>Troef wordt gekozen</div>
            ) : (
              <div className={classes.title}>
                <strong>Troef </strong>
                {gameInfo.trump === 'Club' ? (
                  <img width={40} height={40} src={Club} />
                ) : gameInfo.trump === 'Heart' ? (
                  <img width={40} height={40} src={Heart} />
                ) : gameInfo.trump === 'Spade' ? (
                  <img width={40} height={40} src={Spade} />
                ) : gameInfo.trump === 'Diamond' ? (
                  <img width={40} height={40} src={Diamond} />
                ) : gameInfo.trump === 'NONE' ? (
                  <strong>Geen</strong>
                ) : null}
                <small> ({playgroundInfo.trumpPlayer.displayName})</small>
              </div>
            )}

            {(gameFollowTrump && followTrump01) ||
            (gameFollowTrump && followTrump02) ? (
              <div className={classes.title}>
                <strong>Meegaan </strong>{' '}
                {gameFollowTrump ? <span>Ja</span> : <span>Neen</span>}
                <small>
                  {' '}
                  (
                  {gameFollowTrump && followTrump01
                    ? game.players[
                        `${playgroundInfo.handPlayerMap.closest_opponent}`
                      ].displayName
                    : ''}
                  {gameFollowTrump && followTrump02
                    ? game.players[`${playgroundInfo.handPlayerMap.opponent2}`]
                        .displayName
                    : ''}
                  )
                </small>
              </div>
            ) : (
              <></>
            )}
            <Typography component="h2" className={classes.title}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <strong>Score</strong>
                <ScorePopUp
                  mate={playgroundInfo.mate}
                  player={playgroundInfo.player}
                  opponent1={playgroundInfo.opponent1}
                  opponent2={playgroundInfo.opponent2}
                  hands={game.game.hands}
                  open={game.game.state === 'END' || showScore}
                  team1score={
                    typeof game.game.team_1_score !== 'undefined'
                      ? game.game.team_1_score.score
                      : 0
                  }
                  team2score={
                    typeof game.game.team_2_score !== 'undefined'
                      ? game.game.team_2_score.score
                      : 0
                  }
                  endScore={game.endScore}
                />
              </div>
            </Typography>
            <Score
              mate={playgroundInfo.mate}
              player={playgroundInfo.player}
              opponent1={playgroundInfo.opponent1}
              opponent2={playgroundInfo.opponent2}
              team1score={
                typeof game.game.team_1_score !== 'undefined'
                  ? game.game.team_1_score.score
                  : 0
              }
              team2score={
                typeof game.game.team_2_score !== 'undefined'
                  ? game.game.team_2_score.score
                  : 0
              }
            />

            <Typography component="h2" className={classes.title}>
              <strong>Vorige hand</strong>
              <div style={{ maxWidth: '350px' }}>
                <GameField
                  playerCard={gameInfo.previousHand[auth.uid]}
                  fellowPlayerCard={playgroundInfo.previousHandMateCard}
                  opponent1Card={playgroundInfo.previousHandOpponent1Card}
                  opponent2Card={playgroundInfo.previousHandOpponent2Card}
                />
              </div>
            </Typography>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Chat path={`${gameId}/chat`} />
          </TabPanel>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Game
