import { Ranks, Suits } from '../../Card/constants'

export function assessPlayerCards(playerHand, suitToFollow, currentHandTrump) {
  let ownPlayerCards = []
  // PUSH CARD KEY TO CARD OBJECT.
  // NOTE, FIREBASE DOES NOT KNOW ARRAYS, SO I NEED TO CHECK FOR WHEN THIS BECOMES A OBJECT. LOL
  // REFACTOR NUMBREO UNO I GUESS
  const playableCards = []
  if (typeof playerHand.cards !== 'undefined') {
    if (typeof playerHand.cards !== 'object') {
      playerHand.cards.forEach((card, key) => {
        if (card.suit === suitToFollow) {
          playableCards.push(card)
        }
        ownPlayerCards.push({ card, key })
      })
    } else {
      Object.entries(playerHand.cards).forEach((card) => {
        if (card[1].suit === suitToFollow) {
          playableCards.push(card[1])
        }
        ownPlayerCards.push({
          0: parseInt(card[0]),
          card: card[1]
        })
      })
    }
  }
  // THE PLAYER HAS NO CARDS TO FOLLOW. CHECK IF HE HAS TRUMP TO FOLLOW.
  // IN NO TRUMP ROUND JUST LEAVE HERE
  if (playableCards.length === 0 && currentHandTrump !== 'NONE') {
    suitToFollow = currentHandTrump

    if (typeof playerHand.cards !== 'undefined') {
      if (typeof playerHand.cards !== 'object') {
        playerHand.cards.forEach((card) => {
          if (card.suit === suitToFollow) {
            playableCards.push(card)
          }
        })
      } else {
        Object.entries(playerHand.cards).forEach((card) => {
          if (card[1].suit === suitToFollow) {
            playableCards.push(card[1])
          }
        })
      }
    }
  }
  const orderKeys = [
    Ranks.Seven,
    Ranks.Eight,
    Ranks.Nine,
    Ranks.Jack,
    Ranks.Queen,
    Ranks.King,
    Ranks.Ace,
    Ranks.Ten
  ]
  ownPlayerCards = ownPlayerCards.sort(function (a, b) {
    return orderKeys.indexOf(a.card.rank) - orderKeys.indexOf(b.card.rank)
  })
  const keys = [Suits.Hearts, Suits.Spades, Suits.Diamonds, Suits.Clubs]
  const playerCards = ownPlayerCards.sort(function (a, b) {
    return keys.indexOf(a.card.suit) - keys.indexOf(b.card.suit)
  })

  return {
    playerCards,
    playableCards,
    suitToFollow
  }
}
