import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/auth'
import 'firebase/performance'
import * as config from 'config'

export default function initializeFirebase() {
  const { firebase: firebaseConfig } = config

  // Initialize Firebase instance
  try {
    firebase.initializeApp(config.firebase)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.debug(e)
  }
  // Initialize Firebase analytics if measurementId exists
  if (firebaseConfig.measurementId) {
    // firebase.analytics()
  }
}
