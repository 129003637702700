export default (theme) => {
  return {
    flex: {
      flexGrow: 1
    },
    menuButton: {
      marginLeft: '20px'
    },
    signIn: {
      textDecoration: 'none',
      alignSelf: 'center',
      marginRight: '1rem',
      '&:hover': {
        color: theme.palette.secondary.main
      }
    },
    register: {
      textDecoration: 'none',
      alignSelf: 'center'
    },
    allGames: {
      marginRight: '1rem',
      textDecoration: 'none',
      alignSelf: 'center'
    },
    root: {
      display: 'flex'
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    }
  }
}
