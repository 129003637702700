export default (theme) => ({
  main: {
    ...theme.flexColumnCenter,
    maxWidth: '1550px',
    width: '100%',
    margin: '0 auto'
  },
  mainWrapper: {
    display: 'flex',
    width: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  root: {
    ...theme.flexColumnCenter,
    boxSizing: 'border-box',
    overflowY: 'scroll',
    width: '100%'
  },
  wrapper: {
    width: '100%',
    height: '80vh',
    minHeight: '300px',
    overflowY: 'scroll'
  },
  tiles: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column-reverse'
  },
  gridItem: {
    flexGrow: 1
  },
  top: {
    width: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    margin: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'middle',
    flexWrap: 'wrap',
    '-webkit-flex-flow': 'row wrap'
  },
  alert: {
    width: '100%',
    maxWidth: '950px',
    fontWeight: 'bold',
    marginTop: '20px'
  },
  appBarShift: {
    width: `calc(100% - $350px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: '350px'
  },
  title: {
    flexGrow: 1
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: '350px',
    flexShrink: 0
  },
  drawerPaper: {
    display: 'flex',
    padding: '20px',
    paddingTop: '80px',
    zIndex: '9',
    width: '350px',
    justifyContent: 'space-between'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: '-350px'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(4)
  },
  alertTitle: {
    fontWeight: 'bold'
  }
})
