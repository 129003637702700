export default (theme) => ({
  root: {
    width: '100%',
    ...theme.flexColumnCenter,
    maxWidth: '1550px',
    margin: '0 auto'
  },
  mainWrapper: {
    display: 'flex',
    width: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  playerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column-reverse',
    marginTop: theme.spacing(3)
  },
  top: {
    width: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    margin: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'middle',
    flexWrap: 'wrap',
    '-webkit-flex-flow': 'row wrap'
  },
  card: {
    width: '100%',
    height: '100%',
    backgroundColor: '#FFF',
    padding: theme.spacing(4)
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: '30ch'
  },
  formControl: {
    margin: theme.spacing(2),
    marginRight: '15px !important',
    marginTop: '5px !important',
    width: '30%',
    minWidth: 200
  },
  title: {
    margin: theme.spacing(2)
  },
  marginRight: {
    marginRight: theme.spacing(2)
  },
  margin: {
    margin: theme.spacing(1),
    color: 'red'
  },
  alert: {
    width: '100%',
    maxWidth: '950px',
    fontWeight: 'bold',
    margin: theme.spacing(2)
  },
  inline: {
    display: 'inline',
    alignItems: 'baseline'
  },
  align: {
    display: 'inline-flex',
    alignItems: 'baseline',
    width: '100%'
  }
})
