export default (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    '&:hover': {
      color: ''
    }
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  name: {
    textDecoration: 'none',
    transition: 'all 800ms cubic-bezier(0.25,0.1,0.25,1) 0ms',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginBottom: theme.spacing(2),
    whiteSpace: 'nowrap',
    '&:hover': {
      color: ''
    },
    '&:visited': {
      textDecoration: 'none'
    }
  },
  userIcon: {
    color: 'green',
    margin: theme.spacing(0.5),
    marginRight: theme.spacing(1)
  },
  displayName: {
    color: '#AAAAAA',
    marginTop: theme.spacing(0),
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center'
  },
  small: {
    marginTop: '20px',
    fontSize: '11px',
    color: '#AAAAAA'
  }
})
