import React from 'react'
import PropTypes from 'prop-types'
import firebase from 'firebase/compat/app'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import NotificationsProvider from 'modules/notification/NotificationsProvider'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import ThemeSettings from '../../theme'
import { defaultRRFConfig } from '../../defaultConfig'
import initializeFirebase from '../../initializeFirebase'
import {
  ABOUT_PATH,
  ACCOUNT_PATH,
  LIST_PATH,
  GAME_PATH,
  GAMES_PATH,
  LOGIN_PATH,
  LEADERBOARD_PATH,
  SIGNUP_PATH
} from '../../constants/paths'
import AboutPage from '../../routes/About/components/AboutPage'
import AccountPage from '../../routes/Account/components/AccountPage/AccountPage'
import SignupPage from '../../routes/Signup/components/SignupPage'
import LoginPage from '../../routes/Login/components/LoginPage/LoginPage'
import LeaderboardPage from '../../routes/Leaderboard/components/LeaderboardPage'
import Game from '../../routes/Game/components/Game'
import ProjectsList from '../../routes/Home/components/ProjectsList'
import NotFoundPage from '../../routes/NotFound/components/NotFoundPage'
import CoreLayout from '../../layouts/CoreLayout'
import ProjectData from '../../routes/Projects/components/ProjectData'

const theme = createTheme(ThemeSettings)
initializeFirebase()

function App({ store }) {
  const routes = [
    {
      element: <CoreLayout />,
      children: [
        {
          path: LIST_PATH,
          element: <ProjectsList />
        },
        {
          path: ABOUT_PATH,
          element: <AboutPage />
        },
        {
          path: ACCOUNT_PATH,
          element: <AccountPage />
        },
        {
          path: GAMES_PATH,
          children: [
            {
              path: ':projectId',
              element: <ProjectData />
            }
          ]
        },
        {
          path: GAME_PATH,
          children: [
            {
              path: ':gameId',
              element: <Game />
            }
          ]
        },
        {
          path: SIGNUP_PATH,
          element: <SignupPage />
        },
        {
          path: LOGIN_PATH,
          element: <LoginPage />
        },
        {
          path: LEADERBOARD_PATH,
          element: <LeaderboardPage />
        },
        {
          path: '*',
          element: <NotFoundPage />
        }
      ]
    }
  ]
  const router = createBrowserRouter(routes, {
    basename: ''
  })
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <NotificationsProvider>
          <ReactReduxFirebaseProvider
            firebase={firebase}
            config={defaultRRFConfig}
            dispatch={store.dispatch}>
            <RouterProvider router={router} />
          </ReactReduxFirebaseProvider>
        </NotificationsProvider>
      </Provider>
    </ThemeProvider>
  )
}

App.propTypes = {
  store: PropTypes.object.isRequired
}

export default App
