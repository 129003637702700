import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import styles from './PauseHandDialog.styles'
import Button from '@mui/material/Button'

const useStyles = makeStyles(styles)

function PauseHandDialog({ open, resumeGame }) {
  const classes = useStyles()
  return (
    <Dialog open={open}>
      <DialogTitle id="new-project-dialog-title" className={classes.title}>
        <strong>Game Paused</strong>
      </DialogTitle>
      <DialogContent className={classes.text}>
        <Button onClick={resumeGame} variant="contained" color="secondary">
          Spel hervatten
        </Button>
      </DialogContent>
    </Dialog>
  )
}

PauseHandDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  resumeGame: PropTypes.func.isRequired
}

export default PauseHandDialog
