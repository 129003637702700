export default (theme) => ({
  root: {
    padding: theme.spacing(5),
    minWidth: '450px',
    textAlign: 'center'
  },
  title: {
    margin: theme.spacing(3)
  },
  text: {
    margin: theme.spacing(3),
    marginRight: theme.spacing(5),
    marginLeft: theme.spacing(5)
  }
})
