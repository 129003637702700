import React from 'react'
import ReactDOM from 'react-dom/client'
import { initScripts } from './utils'
import createStore from './store/createStore'
import App from './containers/App'
import './index.css'

// import * as serviceWorker from './serviceWorker'

// Window Variables
// ------------------------------------
initScripts()

// Store Initialization
// ------------------------------------
const initialState = { firebase: { authError: null } }
const store = createStore(initialState)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App store={store} />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()
