import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import styles from './Hand.styles'
import CardSvg from '../Card/CardSvg'

const useStyles = makeStyles(styles)

function Hand({
  cards = [],
  maxAmountOfCards = 8,
  pickCard,
  suitToFollow,
  currentHandSuit,
  currentHandTrump,
  opponent1Card,
  opponent2Card,
  mateCard,
  isAnyCardPlayable,
  noblur
}) {
  const classes = useStyles()
  const cardViewBoxWidth = 169
  const cardViewBoxHeight = 244
  const cardComponents = cards.map((card, index) => (
    <CardSvg
      key={`${card.card.suit}_${card.card.rank}_${index}`}
      card={card.card}
      cardKey={card[0]}
      x={(index * cardViewBoxWidth) / 2}
      pickCard={pickCard}
      suitToFollow={suitToFollow}
      currentHandSuit={currentHandSuit}
      currentHandTrump={currentHandTrump}
      opponent1Card={opponent1Card}
      opponent2Card={opponent2Card}
      mateCard={mateCard}
      isAnyCardPlayable={isAnyCardPlayable}
      noblur={noblur}
    />
  ))
  const amountOfCards = cards.length
  const xCoordinate =
    ((maxAmountOfCards - amountOfCards) * cardViewBoxWidth) / 4
  const viewBoxWidth =
    maxAmountOfCards * cardViewBoxWidth -
    ((maxAmountOfCards - 1) / 2) * cardViewBoxWidth
  const viewBoxHeight = cardViewBoxHeight
  const paddingBottomPercent = (viewBoxHeight / viewBoxWidth) * 100
  const isSupported = checkIfIsSupported()

  function checkIfIsSupported() {
    var isChromium = window.chrome
    var winNav = window.navigator
    var vendorName = winNav.vendor
    var isOpera = typeof window.opr !== 'undefined'
    var isIEedge = winNav.userAgent.indexOf('Edge') > -1
    var isIOSChrome = winNav.userAgent.match('CriOS')
    var isFirefox = typeof InstallTrigger !== 'undefined'

    if (isIOSChrome) {
      return false
      // is Google Chrome on IOS
    } else if (isFirefox) {
      return false
    } else
      return (
        isChromium !== null &&
        typeof isChromium !== 'undefined' &&
        vendorName === 'Google Inc.' &&
        isOpera === false &&
        isIEedge === false
      )
  }

  return (
    <div
      className={classes.container}
      style={{ paddingBottom: `${paddingBottomPercent}%` }}>
      <svg
        viewBox={`-${xCoordinate} 0 ${viewBoxWidth} ${viewBoxHeight}`}
        className={classes.hand}>
        <filter id="blur">
          <feColorMatrix
            type="matrix"
            values="0.3 0.3 0.3 0.2 0
                                       0.3 0.3 0.3 0.2 0
                                       0.3 0.3 0.3 0.2 0
                                       0.3 0.3 0.3 0.3 1"
          />
          {isSupported ? (
            <feComposite
              in="light"
              in2="SourceAlpha"
              operator="in"
              result="lit"
            />
          ) : (
            <></>
          )}
        </filter>
        {cardComponents}
      </svg>
    </div>
  )
}

Hand.propTypes = {
  cards: PropTypes.array,
  maxAmountOfCards: PropTypes.number.isRequired,
  pickCard: PropTypes.func,
  suitToFollow: PropTypes.string,
  currentHandSuit: PropTypes.string,
  currentHandTrump: PropTypes.string,
  opponent1Card: PropTypes.object,
  opponent2Card: PropTypes.object,
  mateCard: PropTypes.object,
  isAnyCardPlayable: PropTypes.bool,
  noblur: PropTypes.bool
}

export default Hand
