import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import {
  LIST_PATH,
  LOGIN_PATH,
  SIGNUP_PATH,
  ABOUT_PATH,
  LEADERBOARD_PATH
} from 'constants/paths'
import AccountMenu from './AccountMenu'
import NavbarWithoutAuth from './NavbarWithoutAuth'
import styles from './Navbar.styles'

const useStyles = makeStyles(styles)

function Navbar() {
  const classes = useStyles()

  // Get auth from redux state
  const auth = useSelector(({ firebase }) => firebase.auth)
  const authExists = isLoaded(auth) && !isEmpty(auth)

  return (
    <NavbarWithoutAuth brandPath={authExists ? LIST_PATH : '/'}>
      <Button
        color="neutral"
        sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
        className={classes.signIn}
        component={Link}
        to={ABOUT_PATH}
        data-test="about-page">
        Over
      </Button>
      <Button
        color="neutral"
        sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
        className={classes.signIn}
        component={Link}
        to={LIST_PATH}
        data-test="games-page">
        Alle spellen
      </Button>
      <Button
        color="neutral"
        sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
        className={classes.signIn}
        component={Link}
        to={LEADERBOARD_PATH}
        data-test="leaderboard-page">
        Leaderboard
      </Button>
      {authExists ? (
        <AccountMenu />
      ) : (
        <span>
          <Button
            className={classes.signIn}
            color="neutral"
            component={Link}
            to={LOGIN_PATH}
            data-test="sign-in">
            Inloggen
          </Button>
          <Button
            className={classes.register}
            variant="contained"
            component={Link}
            to={SIGNUP_PATH}
            data-test="sign-in"
            color="secondary">
            Registreren
          </Button>
        </span>
      )}
    </NavbarWithoutAuth>
  )
}

export default Navbar
