export default (theme) => ({
  root: {
    ...theme.flexColumnCenter,
    justifyContent: 'center',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  underFooter: {
    padding: theme.spacing(1),
    textAlign: 'center'
  }
})
