import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import styles from '../HandBack.styles'
import CardBackSvg from '../../Card/CardBackSvg'

const useStyles = makeStyles(styles)

function HorizontalHandBack({ amountOfCards, maxAmountOfCards = 8 }) {
  const classes = useStyles()

  const cardViewBoxWidth = 169
  const cardViewBoxHeight = 244
  const cardComponents = new Array(amountOfCards)
    .fill(null)
    .map((_, index) => (
      <CardBackSvg key={index} x={(index * cardViewBoxWidth) / 2} />
    ))
  const xCoordinate =
    ((maxAmountOfCards - amountOfCards) * cardViewBoxWidth) / 4
  const viewBoxWidth =
    maxAmountOfCards * cardViewBoxWidth -
    ((maxAmountOfCards - 1) / 2) * cardViewBoxWidth
  const viewBoxHeight = cardViewBoxHeight
  const paddingBottomPercent = (viewBoxHeight / viewBoxWidth) * 100

  return (
    <div
      className={classes.container}
      style={{ paddingBottom: `${paddingBottomPercent}%` }}>
      <svg
        viewBox={`-${xCoordinate} 0 ${viewBoxWidth} ${viewBoxHeight}`}
        className={classes.unSelectable}>
        {cardComponents}
      </svg>
    </div>
  )
}

HorizontalHandBack.propTypes = {
  amountOfCards: PropTypes.number.isRequired,
  maxAmountOfCards: PropTypes.number.isRequired
}

export default HorizontalHandBack
