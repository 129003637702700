import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  useFirebase,
  useFirebaseConnect,
  isLoaded,
  isEmpty
} from 'react-redux-firebase'
import { useNotifications } from 'modules/notification'
import LoadingSpinner from 'components/LoadingSpinner'
import ProjectTile from '../ProjectTile'
import NewProjectTile from '../NewProjectTile'
import NewProjectDialog from '../NewProjectDialog'
import styles from './ProjectsList.styles'
import { GAMES_PATH } from 'constants/paths'
import Chat from '../../../../modules/chat'
import Grid from '@mui/material/Grid'

const useStyles = makeStyles(styles)

function useProjectsList() {
  const { showSuccess, showError } = useNotifications()
  const history = useNavigate()
  const firebase = useFirebase()
  // Create listeners based on current users UID
  useFirebaseConnect([
    {
      path: 'projects',
      storeAs: 'games',
      queryParams: ['limitToLast=50']
    }
  ])
  const projects = useSelector(({ firebase: { ordered } }) => ordered.games)
  const auth = useSelector(({ firebase: { auth } }) => auth)
  const profile = useSelector(({ firebase }) => firebase.profile)
  const [newDialogOpen, changeDialogState] = useState(false)
  const toggleDialog = () => changeDialogState(!newDialogOpen)

  async function authenticateAnonymously() {
    await firebase
      .auth()
      .signInAnonymously()
      .catch(function (error) {
        return showError(error.message)
      })
    const currentUser = await firebase.auth().currentUser
    return currentUser.uid
  }
  async function addProject(newInstance) {
    let userID = auth.uid
    if (!auth.uid) {
      userID = await authenticateAnonymously()
    }
    if (!profile.displayName) {
      profile.displayName = `player_${new Date().getUTCMilliseconds()}`
      await firebase.updateProfile({ displayName: profile.displayName })
    }
    return firebase
      .push('projects', {
        ...newInstance,
        createdBy: userID,
        createdAt: firebase.database.ServerValue.TIMESTAMP,
        endScore: 101
      })
      .then((response) => {
        toggleDialog()
        joinGame(response.getKey())
      })
      .catch((err) => {
        console.error('Error:', err) // eslint-disable-line no-console
        showError(err.message || 'Could not add project')
        return Promise.reject(err)
      })
  }
  async function joinGame(projectId, reconnect = false) {
    let userID = auth.uid
    if (!auth.uid) {
      userID = await authenticateAnonymously()
    }
    if (!profile.displayName) {
      profile.displayName = `player_${new Date().getUTCMilliseconds()}`
      await firebase.updateProfile({ displayName: profile.displayName })
    }
    let updateData = {
      team: 1,
      displayName: profile.displayName,
      createdAt: firebase.database.ServerValue.TIMESTAMP
    }
    if (reconnect) {
      updateData = {
        displayName: profile.displayName
      }
    }
    return firebase
      .update(`projects/${projectId}/players/${userID}`, updateData)
      .then(() => {
        toggleDialog()
        history(`${GAMES_PATH}/${projectId}`)
        showSuccess('Joined Game')
      })
      .catch((err) => {
        history(`${GAMES_PATH}/${projectId}`)
        console.error('Error:', err) // eslint-disable-line no-console
        showError(err.message || 'Could not join Game')
        return Promise.reject(err)
      })
  }

  return { projects, addProject, newDialogOpen, toggleDialog, joinGame, auth }
}

function ProjectsList() {
  const classes = useStyles()
  const { projects, addProject, newDialogOpen, toggleDialog, joinGame, auth } =
    useProjectsList()

  return (
    <main className={classes.main}>
      <NewProjectDialog
        onSubmit={addProject}
        open={newDialogOpen}
        onRequestClose={toggleDialog}
      />
      <div className={classes.top}>
        <h1>Actieve spellen</h1>
        <NewProjectTile onClick={toggleDialog} />
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <div className={classes.root}>
            <section className={classes.wrapper}>
              <div className={classes.tiles}>
                {!isLoaded(projects) && (
                  <div className={classes.tiles}>
                    <LoadingSpinner />
                  </div>
                )}
                {isLoaded(projects) && isEmpty(projects) && (
                  <div className={classes.tiles}>
                    <p>Nog geen spelletjes. Maak 1 aan.</p>
                  </div>
                )}
                {isLoaded(projects) &&
                  !isEmpty(projects) &&
                  projects.map((project) => {
                    let ownProjects = false
                    let isStarted = false
                    const showProject =
                      typeof project.value.players !== 'undefined' &&
                      Object.keys(project.value.players).length > 0 &&
                      Object.keys(project.value.players).length < 4
                    if (showProject) {
                      ownProjects = Object.keys(project.value.players).includes(
                        auth.uid
                      )
                    }
                    if (typeof project.value.players !== 'undefined') {
                      isStarted =
                        typeof project.value.game !== 'undefined' &&
                        project.value.game.state !== 'END' &&
                        Object.keys(project.value.players).length === 4
                    }
                    return showProject || ownProjects || isStarted ? (
                      <ProjectTile
                        key={`Project-${project.key}`}
                        name={project.value.name}
                        projectId={project.key}
                        onJoin={joinGame}
                        players={project.value.players}
                        createdAt={project.value.createdAt}
                      />
                    ) : (
                      <></>
                    )
                  })}
              </div>
            </section>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <Chat path="chat" />
        </Grid>
      </Grid>
    </main>
  )
}

export default ProjectsList
