export default (theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    overflowY: 'scroll'
  },
  gridItem: {
    textAlign: 'center',
    marginTop: theme.spacing(5)
  },
  pane: {
    ...theme.flexColumnCenter,
    justifyContent: 'space-around',
    padding: theme.spacing(6)
  },
  container: {
    position: 'relative',
    height: '0',
    width: '100%',
    padding: '0'
  },
  card: {
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    userSelect: 'none'
  }
})
