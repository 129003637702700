import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import styles from './FollowTrumpDialog.styles'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Spade from 'static/cards/spade.svg'
import Diamond from 'static/cards/diamond.svg'
import Heart from 'static/cards/heart.svg'
import Club from 'static/cards/club.svg'
import Draggable from 'react-draggable'
import Paper from '@mui/material/Paper'

const useStyles = makeStyles(styles)

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

function translateTrump(trump) {
  if (trump === 'Club') {
    return 'Klaver'
  }
  if (trump === 'Heart') {
    return 'Harten'
  }
  if (trump === 'Spade') {
    return 'Pieken'
  }
  if (trump === 'Diamond') {
    return 'Koeken'
  }
}

function FollowTrumpDialog({ trump, open, handleChange }) {
  const classes = useStyles()
  const translatedTrump = translateTrump(trump)
  return (
    <Dialog
      open={open}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title">
      <DialogTitle id="draggable-dialog-title">
        <div style={{ textAlign: 'center' }}>
          {trump === 'Club' ? (
            <img width={48} height={48} src={Club} />
          ) : trump === 'Heart' ? (
            <img width={48} height={48} src={Heart} />
          ) : trump === 'Spade' ? (
            <img width={48} height={48} src={Spade} />
          ) : trump === 'Diamond' ? (
            <img width={48} height={48} src={Diamond} />
          ) : null}
        </div>
        <strong>De troef is {translatedTrump}, meegaan?</strong>
      </DialogTitle>
      <DialogContent>
        <FormControl className={classes.formControl} fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={trump}
            onChange={handleChange}>
            <MenuItem value="true">Ja</MenuItem>
            <MenuItem value="false">Neen</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
    </Dialog>
  )
}

FollowTrumpDialog.propTypes = {
  trump: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired
}

export default FollowTrumpDialog
