export function assessPlayGround(
  game,
  currentHand,
  previousHand,
  round,
  playerId
) {
  const player = game.players[`${playerId}`]

  const handPlayerMap = {}
  Object.entries(currentHand).forEach((player) => {
    if (player[1].role === 'opponent') {
      // SECOND TIME OPPONENT LOOPS AND SITS CLOSER
      if (
        handPlayerMap.closest_opponent &&
        player[1].seat < handPlayerMap.closest_seat
      ) {
        handPlayerMap.closest_opponent = player[0]
        handPlayerMap.closest_seat = player[1].seat
      }
      // SECOND TIME OPPONENT LOOPS AND WE ALREADY HAD CLOSEST ONE, PUT THIS ONE ON OPP2
      if (
        handPlayerMap.closest_opponent &&
        player[1].seat > handPlayerMap.closest_seat
      ) {
        handPlayerMap.opponent2 = player[0]
      }
      // FIRST TIME OPPONENT LOOPS, JUST PUT EVERYTHING ON THE SAME PLAYER
      if (!handPlayerMap.closest_opponent) {
        handPlayerMap.opponent2 = player[0]
        handPlayerMap.closest_opponent = player[0]
        handPlayerMap.closest_seat = player[1].seat
      }
    }
    if (player[1].role === 'trumpchooser') {
      handPlayerMap.trump = player[0]
    }
    if (player[1].role === 'trumpchooserMate') {
      handPlayerMap.trumpChooserMate = player[0]
    }
    if (player[0] === playerId) {
      handPlayerMap.me = player[0]
    }
  })

  const isOpponent =
    handPlayerMap.me === handPlayerMap.closest_opponent ||
    handPlayerMap.me === handPlayerMap.opponent2

  const trumpPlayer = game.players[`${handPlayerMap.trump}`]
  let opponent1 = game.players[`${handPlayerMap.trump}`]
  let opponent2 = game.players[`${handPlayerMap.trumpChooserMate}`]
  let mate = game.players[`${handPlayerMap.closest_opponent}`]
  let opponent1Card = round[`${handPlayerMap.trump}`]
  let opponent2Card = round[`${handPlayerMap.trumpChooserMate}`]
  let mateCard = round[`${handPlayerMap.closest_opponent}`]
  let opponent1Game = currentHand[`${handPlayerMap.trump}`]
  let opponent2Game = currentHand[`${handPlayerMap.trumpChooserMate}`]
  let mateGame = currentHand[`${handPlayerMap.closest_opponent}`]
  let previousHandOpponent1Card = previousHand[`${handPlayerMap.trump}`]
  let previousHandOpponent2Card =
    previousHand[`${handPlayerMap.trumpChooserMate}`]
  let previousHandMateCard = previousHand[`${handPlayerMap.closest_opponent}`]
  // SWITCH PLAYERS BC MIRROR SHIZZLE
  if (handPlayerMap.me === handPlayerMap.trump) {
    opponent1 = game.players[`${handPlayerMap.closest_opponent}`]
    opponent2 = game.players[`${handPlayerMap.opponent2}`]
    mate = game.players[`${handPlayerMap.trumpChooserMate}`]
    opponent1Card = round[`${handPlayerMap.closest_opponent}`]
    opponent2Card = round[`${handPlayerMap.opponent2}`]
    mateCard = round[`${handPlayerMap.trumpChooserMate}`]
    opponent1Game = currentHand[`${handPlayerMap.closest_opponent}`]
    opponent2Game = currentHand[`${handPlayerMap.opponent2}`]
    mateGame = currentHand[`${handPlayerMap.trumpChooserMate}`]
    previousHandOpponent1Card =
      previousHand[`${handPlayerMap.closest_opponent}`]
    previousHandOpponent2Card = previousHand[`${handPlayerMap.opponent2}`]
    previousHandMateCard = previousHand[`${handPlayerMap.trumpChooserMate}`]
  }
  if (handPlayerMap.me === handPlayerMap.closest_opponent) {
    opponent1 = game.players[`${handPlayerMap.trumpChooserMate}`]
    opponent2 = game.players[`${handPlayerMap.trump}`]
    mate = game.players[`${handPlayerMap.opponent2}`]
    opponent1Card = round[`${handPlayerMap.trumpChooserMate}`]
    opponent2Card = round[`${handPlayerMap.trump}`]
    mateCard = round[`${handPlayerMap.opponent2}`]
    opponent1Game = currentHand[`${handPlayerMap.trumpChooserMate}`]
    opponent2Game = currentHand[`${handPlayerMap.trump}`]
    mateGame = currentHand[`${handPlayerMap.opponent2}`]
    previousHandOpponent1Card =
      previousHand[`${handPlayerMap.trumpChooserMate}`]
    previousHandOpponent2Card = previousHand[`${handPlayerMap.trump}`]
    previousHandMateCard = previousHand[`${handPlayerMap.opponent2}`]
  }
  if (handPlayerMap.me === handPlayerMap.trumpChooserMate) {
    opponent1 = game.players[`${handPlayerMap.opponent2}`]
    opponent2 = game.players[`${handPlayerMap.closest_opponent}`]
    mate = game.players[`${handPlayerMap.trump}`]
    opponent1Card = round[`${handPlayerMap.opponent2}`]
    opponent2Card = round[`${handPlayerMap.closest_opponent}`]
    mateCard = round[`${handPlayerMap.trump}`]
    opponent1Game = currentHand[`${handPlayerMap.opponent2}`]
    opponent2Game = currentHand[`${handPlayerMap.closest_opponent}`]
    mateGame = currentHand[`${handPlayerMap.trump}`]
    previousHandOpponent1Card = previousHand[`${handPlayerMap.opponent2}`]
    previousHandOpponent2Card =
      previousHand[`${handPlayerMap.closest_opponent}`]
    previousHandMateCard = previousHand[`${handPlayerMap.trump}`]
  }

  return {
    player,
    opponent1,
    opponent2,
    mate,
    opponent1Card,
    opponent2Card,
    mateCard,
    previousHandOpponent1Card,
    previousHandOpponent2Card,
    previousHandMateCard,
    trumpPlayer,
    isOpponent,
    opponent1Game,
    opponent2Game,
    mateGame,
    handPlayerMap
  }
}
