import React from 'react'
import { makeStyles } from '@mui/styles'
import styles from './AboutPage.styles'

const useStyles = makeStyles(styles)

function AboutPage() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <h1>Manillen online</h1>
      <p>
        Manillen online is een online versie van het kaartspel manillen. Je
        hoeft je niet te registreren om te kunnen spelen.
        <br /> Het doel was om het populaire kaartspel manillen (1) "stateless"
        en (2) "serverless" te maken. Dit wil zeggen dat je (1) Je kunt (als je
        ingelogd bent) stoppen met spelen, en nadien terug verderspelen. De
        spelstatus wordt bewaard.
        <br /> Zo kun je zelfs van apparaat wisselen tijdens het spelen! En (2)
        "serverless" te maken, dit wil zeggen dat er in theorie geen limieten
        zijn op het aantal spellen / spelers die we hebben.
      </p>
      <p>
        In deze versie kun je zowel manillen met 4 online, als manillen online
        tegen een computer. Je kunt ook samenspelen met een computer.
      </p>
      <h2>Spelregels manillen online</h2>
      <h4>Waarde van de kaarten:</h4>
      <p>In order van hoog naar laag:</p>
      <ul>
        <li>
          <strong> 10 / Manille</strong>: 5 punten
        </li>
        <li>
          <strong> 1 / Aas / Ace </strong>: 4 punten
        </li>
        <li>
          <strong> Koning </strong>: 3 punten
        </li>
        <li>
          <strong> Dame </strong>: 2 punten
        </li>
        <li>
          <strong> Boer </strong>: 1 punten
        </li>
        <li>
          <strong> 9 / 8 / 7 / 6 </strong>: 0 punten
        </li>
      </ul>
      <h4>Doel van manillen:</h4>
      <p>
        Het doel van het spel is om met je teamgenoot tegen 2 vijandige spelers
        om het eerst tot het maximale aantal punten te raken. Een boom (tot 101)
        punten is de meest traditionele gamemodus. Je kan ook een halve boom
        (tot 51 punten) spelen.
        <br /> Punten worden verdiend door handen (rondes) te winnen.
      </p>
      <h4>Spelverloop:</h4>
      <p>Hoe verloopt een spel van manillen online op manillen.eu?</p>
      <ol>
        <li>
          Maak een spel aan door te klikken op "Maak spel", of doe mee met een
          spel door te klikken op "meespelen"
        </li>
        <li>
          Verdeel de teams. Er zijn 2 teams. Elk team bestaat uit 2 spelers. Een
          speler kan ook een computer zijn.
        </li>
        <li>Druk op "Game starten"</li>
        <li>De kaarten zullen automatisch verdeeld worden</li>
        <li>
          <strong>Troef kiezen</strong> Op basis van de kaarten die je hebt, 1
          speler mag "troef kiezen". Dit wil zeggen dat je met deze kaart
          doorheen de hand op bepaalde momenten de ronde kan kopen. <br />
          Je kan ook kiezen om zonder troef te spelen. Dit wil zeggen dat er
          geen "troefkaart" is, en dat de gewonnen score x2 gedaan wordt.
        </li>
        <li>
          {' '}
          <strong>Meegaan</strong> Hierop krijgen de 2 tegenspelers de
          mogelijkheid om "mee te gaan".
        </li>
      </ol>
      <h2>Vragen of feedback?</h2>
      <p>
        Manillen online is een hobbyproject. Gelieve vragen of suggesties te
        richten naar{' '}
        <a
          href="https://www.instagram.com/manillen.eu/"
          target="_blank"
          rel="noreferrer">
          @manillen.eu
        </a>{' '}
        via Instagram. Bedankt!
      </p>
      <h3>Changelog</h3>
      <small>
        If you reported a bug / are waiting for a new feature, you can read
        about the fix here.
      </small>
      <ul>
        <li>
          <code>
            <b>0.0.0.66: Revamp</b> Updated all packages. Added WIP leaderboard
            and chat everywhere! Also added playing against ChatGPT (WIP){' '}
          </code>
        </li>
        <li>
          <code>
            <b>0.0.0.65: fix backs of cards</b> Fixed a bug where the backs of
            cards were not showing!{' '}
          </code>
        </li>
        <li>
          <code>
            <b>0.0.0.64: back online</b> Manillen online is back online!{' '}
          </code>
        </li>
        <li>
          <code>
            <b>0.0.0.63: emotes</b> Allows the player to give a reaction while
            playing.{' '}
          </code>
        </li>
        <li>
          <code>
            <b>0.0.0.62: Fix not starting game</b> Fixed a bug where the game
            would not start because the "hand" was not shuffeled.
          </code>{' '}
        </li>
        <li>
          <code>
            <b>0.0.0.61: Fix not generated temporary username</b> Fixed a bug
            where the username would not be generated after joining a game
          </code>{' '}
        </li>
      </ul>
      <h3>Upcoming features</h3>
      <small>The roadmap for manillen.eu</small>
      <ul>
        <li>
          <code>
            <b>Shuffle previous deck cards</b> As a player I want my cards
            during my game not to be totally random, but being shuffled from the
            previous round like a real game.
          </code>{' '}
        </li>
        <li>
          <code>
            <b>Matchmaking</b> As a player, I want to be paired up against
            evenly skilled players
          </code>{' '}
        </li>
      </ul>
    </div>
  )
}

export default AboutPage
