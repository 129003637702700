import React from 'react'
import { Card as CardData } from '../models/Card'
import { makeStyles } from '@mui/styles'
import styles from '../../Card/Card.styles'
import CardSvg from '../CardSvg'
import PropTypes from 'prop-types'

const useStyles = makeStyles(styles)

function VerticalCard({ card, noblur = false }) {
  const classes = useStyles()
  const viewBoxWidth = 169
  const viewBoxHeight = 244
  const paddingBottomPercent = (viewBoxHeight / viewBoxWidth) * 100

  return (
    <div
      className={classes.container}
      style={{ paddingBottom: `${paddingBottomPercent}%` }}>
      <svg
        viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
        className={classes.card}>
        <CardSvg card={card} noblur={noblur} />
      </svg>
    </div>
  )
}

VerticalCard.propTypes = {
  card: CardData,
  noblur: PropTypes.bool
}

export default VerticalCard
