export function assessGameInfo(hand, game, playerId) {
  const handId = Object.keys(hand)[0]
  const currentHand = hand[`${handId}`]
  const playerAtHandId = currentHand.player_turn
  const isPlayerAtHand = playerAtHandId === playerId
  const playerAtHand = game.players[`${playerAtHandId}`]
  const playerHand = currentHand[`${playerId}`]
  const suitToFollow =
    typeof currentHand.suit === 'undefined' ? '' : currentHand.suit
  const currentHandSuit = suitToFollow
  const currentHandTrump =
    typeof currentHand.trump === 'undefined' ? '' : currentHand.trump
  const round =
    typeof currentHand.playground === 'undefined' ? [] : currentHand.playground
  const previousHand =
    typeof currentHand.previous_hand === 'undefined'
      ? []
      : currentHand.previous_hand
  const trump = hand[`${handId}`].trump

  return {
    handId,
    isPlayerAtHand,
    playerAtHand,
    playerAtHandId,
    playerHand,
    suitToFollow,
    currentHand,
    currentHandSuit,
    currentHandTrump,
    round,
    previousHand,
    trump
  }
}
