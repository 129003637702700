/**
 * NOTE: This file is ignored from git tracking. In a CI environment it is
 * generated by firebase-ci based on config in .firebaserc (see .gitlab-ci.yaml).
 * This is done so that environment specific settings can be applied.
 */

export const env = 'dev'
export const version = '0.0.7'

// Config for firebase
export const firebase = {
  apiKey: "AIzaSyDGoz9HCY4onXQ4G4Pyq2XxwbHXYWFr_CE",
  authDomain: "manillen-123f4.firebaseapp.com",
  databaseURL: "https://manillen-123f4-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "manillen-123f4",
  storageBucket: "manillen-123f4.appspot.com",
  messagingSenderId: "511405051622",
  appId: "1:511405051622:web:6308f82bc01c2df1683527",
  measurementId: "G-FBQNWFYNCX"
}

export const segmentId = ''
export const publicVapidKey = 'BKw4uevw1TsEhzjHQgItQEqq9j-DYYxaTRzT-1CdqEaiqhVWkwSe2-7z58fpe4hLbFqNeSiDK89ecFVT0HJlATQ'
export const sentryDsn = 'https://8bc7ac9df91444e18365fa6a4bde40ac@o4505050127859712.ingest.sentry.io/4505050131595264'
