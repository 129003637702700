export default (theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    overflowY: 'scroll'
  },
  gridItem: {
    textAlign: 'center',
    marginTop: theme.spacing(1)
  },
  pane: {
    ...theme.flexColumnCenter,
    justifyContent: 'space-around',
    padding: theme.spacing(1)
  },
  container: {
    height: '100%',
    minHeight: '450px',
    marginTop: '1rem'
  },
  row1: {
    display: 'flex',
    alignItems: 'center',
    height: '25%'
  },
  row3: {
    display: 'flex',
    alignItems: 'center',
    height: '25%'
  },
  row2: {
    display: 'flex',
    height: '25%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  verticalCardContainer: {
    width: '27%',
    margin: 'auto'
  },
  horizontalCardContainer: {
    display: 'inline-block',
    width: '35%',
    margin: '0'
  }
})
