export default () => ({
  container: {
    height: 0,
    width: '100%'
  },
  unSelectable: {
    userSelect: 'none'
  },
  hand: {
    userSelect: 'none',
    overflow: 'hidden'
  },
  card: {
    transition: 'transform .5s',
    position: 'relative',
    display: 'block',
    overflow: 'hidden',
    '&:hover': {
      cursor: 'pointer',
      transition: 'transform .3s',
      marginBottom: '5px'
    }
  }
})
