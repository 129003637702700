import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import styles from './NewTrumpDialog.styles'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Spade from 'static/cards/spade.svg'
import Diamond from 'static/cards/diamond.svg'
import Heart from 'static/cards/heart.svg'
import Club from 'static/cards/club.svg'
import Paper from '@mui/material/Paper'
import Draggable from 'react-draggable'

const useStyles = makeStyles(styles)

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

function NewTrumpDialog({ trump, open, handleChange }) {
  const classes = useStyles()
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title">
      <DialogTitle id="draggable-dialog-title" className={classes.title}>
        <strong>Kies troef</strong>
      </DialogTitle>
      <DialogContent>
        <FormControl className={classes.formControl} fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={trump}
            onChange={handleChange}>
            <MenuItem value="Heart">
              <img
                width={24}
                height={24}
                src={Heart}
                style={{ marginRight: '10px' }}
              />
              Harten
            </MenuItem>
            <MenuItem value="Spade">
              <img
                width={24}
                height={24}
                src={Spade}
                style={{ marginRight: '10px' }}
              />
              Pikken
            </MenuItem>
            <MenuItem value="Diamond">
              <img
                width={24}
                height={24}
                src={Diamond}
                style={{ marginRight: '10px' }}
              />
              Koeken
            </MenuItem>
            <MenuItem value="Club">
              <img
                width={24}
                height={24}
                src={Club}
                style={{ marginRight: '10px' }}
              />
              Klavers
            </MenuItem>
            <MenuItem value="NONE">Geen</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
    </Dialog>
  )
}

NewTrumpDialog.propTypes = {
  trump: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired
}

export default NewTrumpDialog
