export default (theme) => ({
  root: {
    ...theme.flexRowCenter,
    cursor: 'pointer',
    overflow: 'hidden',
    maxHeight: '45px',
    marginTop: '15px'
  },
  newIcon: {
    width: '1rem',
    height: '1rem',
    transition: 'all 800ms cubic-bezier(0.25,0.1,0.25,1) 0ms',
    '&:hover': {
      color: '#757575'
    },
    margin: theme.spacing(0.5)
  }
})
