import React from 'react'
import { makeStyles } from '@mui/styles'
import styles from './LeaderboardPage.styles'

const useStyles = makeStyles(styles)

function LeaderboardPage() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <h1>Leaderboard</h1>
      <p>Coming soon</p>
    </div>
  )
}

export default LeaderboardPage
