export function isCardPlayable(
  playerCard,
  opponent1Card,
  mateCard,
  opponent2Card,
  currentHandSuit, // THE SUIT THAT IS PLAYED BY FIRST PLAYER
  suitToFollow, // WILL BE TRUMP IF PLAYER HAS NO SUIT TO FOLLOW
  currentHandTrump,
  isAnyCardPlayable = false // IF I CAN'T FOLLOW, THIS WILL BE TRUE
) {
  // IsAnyCardPlayable is if you need to buy or need to be able to play everything.
  if (isAnyCardPlayable === 'justPlayEverything') {
    return true
  }

  if (!opponent1Card && !mateCard && !opponent2Card) {
    return true
  }

  if (currentHandTrump === 'NONE' && isAnyCardPlayable === false) {
    return (
      weWinForSuit(
        playerCard,
        opponent1Card,
        opponent2Card,
        mateCard,
        currentHandSuit
      ) && playerCard.suit === suitToFollow
    )
  }

  if (isAnyCardPlayable) {
    // LET ME PLAY EVERYTHING IF MATE WINS AND I CAN'T FOLLOW
    if (
      weWinForSuit(
        playerCard,
        opponent1Card,
        opponent2Card,
        mateCard,
        currentHandSuit
      )
    ) {
      return true
    }
    // ELSE JUST CHECK FOR SUIT, THIS WILL BE TRUMP I THINK?
    return playerCard.suit === suitToFollow
  }

  if (
    !isTrumpInPlay(opponent1Card, opponent2Card, mateCard, currentHandTrump)
  ) {
    if (playerCard.suit === currentHandTrump) {
      return false
    }
    return (
      weWinForSuit(
        playerCard,
        opponent1Card,
        opponent2Card,
        mateCard,
        currentHandSuit
      ) && playerCard.suit === currentHandSuit
    )
  }

  // SOMEBODY STARTED TRUMP, AND I CAN FOLLOW
  if (
    currentHandSuit === currentHandTrump &&
    currentHandSuit === suitToFollow
  ) {
    // IF MATE HAS HIGHEST TRUMP
    if (
      weWinForSuit(
        null,
        opponent1Card,
        opponent2Card,
        mateCard,
        currentHandTrump
      )
    ) {
      return playerCard.suit === currentHandTrump
    }
    // I NEED TO GO OVER THEM
    return (
      isHigherThenOpponents(playerCard, opponent1Card, opponent2Card) &&
      playerCard.suit === currentHandTrump
    )
  }

  if (
    isTrumpManillePlayed(
      playerCard,
      opponent1Card,
      opponent2Card,
      mateCard,
      currentHandTrump
    )
  ) {
    if (
      suitToFollow === currentHandTrump &&
      currentHandSuit !== currentHandTrump
    ) {
      return true
    }
    return playerCard.suit === currentHandSuit
  }

  // IF MATE HAS HIGHEST (WITH TRUMP)
  if (
    weWinForSuit(null, opponent1Card, opponent2Card, mateCard, currentHandTrump)
  ) {
    // I CAN'T FOLLOW (I HAVE TRUMP AND RANDOM)
    if (suitToFollow === currentHandTrump) {
      return true
    }
    return playerCard.suit === currentHandSuit
  }

  return (
    weWinForSuit(
      playerCard,
      opponent1Card,
      opponent2Card,
      mateCard,
      currentHandTrump
    ) && suitToFollow === currentHandTrump
  )
}

function convertRank(rank) {
  if (parseInt(rank) === 1) {
    rank = 19
  }

  if (parseInt(rank) === 10) {
    rank = 20
  }

  return parseInt(rank)
}

function isTrumpManillePlayed(
  playerCard,
  opponent1Card,
  opponent2Card,
  mateCard,
  currentHandTrump
) {
  if (isTrumpManille(playerCard, currentHandTrump)) {
    return true
  }
  if (opponent2Card) {
    if (isTrumpManille(opponent2Card, currentHandTrump)) {
      return true
    }
  }
  if (mateCard) {
    if (isTrumpManille(mateCard, currentHandTrump)) {
      return true
    }
  }
  if (opponent1Card) {
    if (isTrumpManille(opponent1Card, currentHandTrump)) {
      return true
    }
  }
  return false
}

function isTrumpManille(card, currentHandTrump) {
  return card.suit === currentHandTrump && parseInt(card.rank) === 10
}

function isTrumpInPlay(
  opponent1Card,
  opponent2Card,
  mateCard,
  currentHandTrump
) {
  if (opponent1Card) {
    if (opponent1Card.suit === currentHandTrump) {
      return true
    }
  }

  if (opponent2Card) {
    if (opponent2Card.suit === currentHandTrump) {
      return true
    }
  }

  if (mateCard) {
    if (mateCard.suit === currentHandTrump) {
      return true
    }
  }

  return false
}

// DO WE WIN THIS HAND FOR THE GIVEN SUIT?
// THE SUIT CAN BE TRUMP OR NOT, THAT'S WHY ITS VARIABLE.
export function weWinForSuit(
  playerCard,
  opponent1Card,
  opponent2Card,
  mateCard,
  suitToCheck
) {
  // TAKE CARDS OF A DIFFERENT SUIT OUT OF THE EQUATION
  if (opponent1Card) {
    if (opponent1Card.suit !== suitToCheck) {
      opponent1Card = null
    }
  }
  if (opponent2Card) {
    if (opponent2Card.suit !== suitToCheck) {
      opponent2Card = null
    }
  }
  if (mateCard) {
    if (mateCard.suit !== suitToCheck) {
      mateCard = null
    }
  }
  if (playerCard) {
    if (playerCard.suit !== suitToCheck) {
      playerCard = null
    }
  }
  if (!mateCard) {
    return isHigherThenOpponents(playerCard, opponent1Card, opponent2Card)
  }
  if (!opponent1Card && !opponent2Card) {
    return true
  }
  if (!isHigherThenOpponents(mateCard, opponent1Card, opponent2Card)) {
    return isHigherThenOpponents(playerCard, opponent1Card, opponent2Card)
  }
  return true
}

function isHigherThenOpponents(playerCard, opponent1Card, opponent2Card) {
  if (playerCard) {
    if (!opponent1Card && opponent2Card) {
      return convertRank(opponent2Card.rank) < convertRank(playerCard.rank)
    }
    if (!opponent2Card && opponent1Card) {
      return convertRank(opponent1Card.rank) < convertRank(playerCard.rank)
    }
    if (opponent2Card && opponent1Card) {
      if (convertRank(opponent1Card.rank) < convertRank(opponent2Card.rank)) {
        return convertRank(opponent2Card.rank) < convertRank(playerCard.rank)
      }
      return convertRank(opponent1Card.rank) < convertRank(playerCard.rank)
    }
  }
  return false
}
