import React, { useRef } from 'react'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import styles from './Chat.styles'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { useForm } from 'react-hook-form'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { useFirebaseConnect, isEmpty, useFirebase } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import Alert from '@mui/lab/Alert'
import PropTypes from 'prop-types'

const useStyles = makeStyles(styles)

function Chat({ path }) {
  const firebase = useFirebase()
  const classes = useStyles()
  const auth = useSelector(({ firebase }) => firebase.auth)
  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState: { isSubmitting, isValid }
  } = useForm({ mode: 'onChange' })

  const messagesEndRef = useRef(null)

  // Create listener for projects
  useFirebaseConnect([
    {
      path,
      storeAs: 'chat',
      queryParams: ['limitToLast=50']
    }
  ])

  // Get messages from redux state
  const messages = useSelector(({ firebase: { ordered } }) => ordered.chat)
  function postMessage(message) {
    if (auth.uid) {
      reset()
      scrollToBottom()
      return firebase.push(path, {
        ...message,
        userId: auth.uid,
        displayName: auth.displayName,
        createdAt: firebase.database.ServerValue.TIMESTAMP
      })
    }
  }

  function scrollToBottom() {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Box className={classes.chatContainer}>
      {isEmpty(auth) && (
        <Alert className={classes.alert} severity="warning">
          Nieuw: Speel zonder je te registreren. Druk op 'nieuw spel' of
          'meespelen' om meteen te spelen zonder een account aan te maken.
        </Alert>
      )}
      {!isEmpty(auth) && isEmpty(auth.email) && (
        <Alert className={classes.alert} severity="info">
          Claim je account! Kies je eigen username door{' '}
          <a href="/account">je account</a> aan te maken.
        </Alert>
      )}
      <List className={classes.messagesContainer}>
        {!isEmpty(messages) &&
          messages.map((message) => {
            return (
              <ListItem
                alignItems="flex-start"
                key={`Project-${message.key}`}
                className={classes.listItem}>
                <ListItemText
                  // primary={message.value.message}
                  secondary={
                    <div className={classes.smaller}>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.date}>
                        [
                        {new Date(message.value.createdAt).toLocaleTimeString()}
                        ]
                      </Typography>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inlineDisplayName}
                        color="primary">
                        {message.value.displayName}
                      </Typography>
                      :
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}>
                        {message.value.message}
                      </Typography>
                    </div>
                  }
                />
              </ListItem>
            )
          })}
        <ListItem
          style={{ float: 'left', clear: 'both' }}
          ref={messagesEndRef}
        />
      </List>
      <form onSubmit={handleSubmit(postMessage)}>
        <TextField
          error={errors ? errors.message : ''}
          helperText={
            errors ? errors.message && 'Message can not be empty' : ''
          }
          name="message"
          {...register('message', { required: true })}
          label={auth.uid ? 'Bericht' : 'Gelieve in te loggen om te chatten.'}
          margin="normal"
          fullWidth
        />
        <div>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={isSubmitting || !isValid || !auth.uid}>
            {isSubmitting ? 'Verzenden ...' : 'Verzend'}
          </Button>
        </div>
      </form>
    </Box>
  )
}

Chat.propTypes = {
  path: PropTypes.object.isRequired
}

export default Chat
