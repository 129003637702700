import React from 'react'
import { makeStyles } from '@mui/styles'
import styles from './GameField.styles'
import Card from '../Card'
import PropTypes from 'prop-types'

const useStyles = makeStyles(styles)

function GameField({
  playerCard,
  fellowPlayerCard,
  opponent1Card,
  opponent2Card
}) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.row1}>
        <div className={classes.verticalCardContainer}>
          {fellowPlayerCard && <Card noblur card={fellowPlayerCard} />}
        </div>
      </div>
      <div className={classes.row2}>
        <div className={classes.horizontalCardContainer}>
          {opponent1Card && (
            <Card
              className={classes.horizontalCard1}
              noblur
              horizontal
              card={opponent1Card}
            />
          )}
        </div>
        <div className={classes.horizontalCardContainer}>
          {opponent2Card && (
            <Card
              className={classes.horizontalCard2}
              noblur
              horizontal
              card={opponent2Card}
            />
          )}
        </div>
      </div>
      <div className={classes.row3}>
        <div className={classes.verticalCardContainer}>
          {playerCard && <Card noblur card={playerCard} />}
        </div>
      </div>
    </div>
  )
}

GameField.propTypes = {
  playerCard: PropTypes.object,
  fellowPlayerCard: PropTypes.object,
  opponent1Card: PropTypes.object,
  opponent2Card: PropTypes.object
}

export default GameField
