import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { isEmpty, isLoaded } from 'react-redux-firebase'
import styles from '../Game/Game.styles'
import LoadingSpinner from 'components/LoadingSpinner'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Happy from 'static/happy.png'
import Sad from 'static/sad.png'
import Button from '@mui/material/Button'
import { LIST_PATH } from 'constants/paths'

const useStyles = makeStyles(styles)

function ScoreBoard({
  player,
  mate,
  opponent1,
  opponent2,
  hands,
  open,
  team1score,
  team2score,
  endScore,
  hideScoreAction
}) {
  const classes = useStyles()

  if (!isLoaded(hands)) {
    return <LoadingSpinner />
  }

  const widderTeamId = player.team
  const otherTeamId = widderTeamId === 1 ? 2 : 1

  let scores = []
  function calculatescore(hands) {
    hands = Object.values(hands)
    hands.pop()
    hands.map((hand, i) => {
      scores.push({
        team_1_score: 0,
        team_2_score: 0
      })
      scores[i][`team_${widderTeamId}_score`] = buildScore(
        hand,
        widderTeamId,
        i
      )
      scores[i][`team_${otherTeamId}_score`] = buildScore(hand, otherTeamId, i)
    })
    return scores
  }

  scores = calculatescore(hands)
  const finalScores = []
  finalScores.team_1_score = team1score
  finalScores.team_2_score = team2score

  const teamWon = endScore <= team1score || endScore <= team2score
  const wonTeamId = endScore <= team1score ? 2 : 1

  function convertScore(score) {
    return parseInt(score) - 30 > 0 ? parseInt(score) - 30 : 0
  }

  function buildScore(hand, id, i) {
    if (!hand[`team_${id}_score`]) {
      return 0
    }
    const isDoubleScore = checkIsDoubleScore(hand)
    const previousScore =
      typeof scores[i - 1] !== 'undefined'
        ? scores[i - 1][`team_${id}_score`]
        : 0
    let scoreToAdd = convertScore(hand[`team_${id}_score`].score)
    scoreToAdd = isDoubleScore ? scoreToAdd * 2 : scoreToAdd
    return scoreToAdd !== 0 ? previousScore + scoreToAdd : 0
  }

  function checkIsDoubleScore(hand) {
    let isDoubleScore = false
    if (hand.trump === 'NONE') {
      return true
    }
    Object.values(hand).map((player) => {
      if (typeof player.followTrump !== 'undefined' && player.followTrump) {
        isDoubleScore = true
      }
    })
    return isDoubleScore
  }

  function leaveGame() {
    window.location.href = `${LIST_PATH}`
  }

  return (
    <Dialog open={open} maxWidth="lg" scroll="paper">
      <DialogTitle id="draggable-dialog-title" style={{ textAlign: 'center' }}>
        {teamWon ? (
          wonTeamId !== widderTeamId ? (
            <img width={148} height={148} src={Happy} />
          ) : (
            <img width={148} height={148} src={Sad} />
          )
        ) : null}{' '}
        <br />
        <br />
        {teamWon ? (
          wonTeamId !== widderTeamId ? (
            <strong>Je bent gewonnen!</strong>
          ) : (
            <strong>Je bent verloren!</strong>
          )
        ) : null}
        {teamWon ? (
          <div
            style={{
              textAlign: 'center',
              marginTop: '35px',
              marginBottom: '35px'
            }}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => leaveGame()}>
              Spel Verlaten
            </Button>
          </div>
        ) : (
          <div
            style={{
              textAlign: 'center',
              marginTop: '35px',
              marginBottom: '35px'
            }}>
            <Button
              color="primary"
              variant="outlined"
              onClick={hideScoreAction}>
              Sluiten
            </Button>
          </div>
        )}
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <strong>Widder</strong> <br />
                  <small>{player.displayName}</small> /{' '}
                  <small>{mate.displayName}</small>
                </TableCell>
                <TableCell align="center">
                  <strong>Zidder</strong> <br />
                  <small>{opponent1.displayName}</small> /{' '}
                  <small>{opponent2.displayName}</small>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(scores) &&
                scores.map((hand, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell align="center">
                        {hand[`team_${widderTeamId}_score`]}
                      </TableCell>
                      <TableCell align="center">
                        {hand[`team_${otherTeamId}_score`]}
                      </TableCell>
                    </TableRow>
                  )
                })}
              <TableRow key="final">
                <TableCell align="center">
                  <strong>{finalScores[`team_${widderTeamId}_score`]}</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>{finalScores[`team_${otherTeamId}_score`]}</strong>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  )
}

ScoreBoard.propTypes = {
  player: PropTypes.object,
  mate: PropTypes.object,
  opponent1: PropTypes.object,
  opponent2: PropTypes.object,
  hands: PropTypes.object,
  open: PropTypes.bool.isRequired,
  team1score: PropTypes.number,
  team2score: PropTypes.number,
  endScore: PropTypes.number,
  hideScoreAction: PropTypes.func
}

export default ScoreBoard
