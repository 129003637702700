export default (theme) => ({
  root: {
    overflowY: 'scroll',
    justifyContent: 'center',
    alignItems: 'center'
  },
  pane: {
    justifyContent: 'space-around'
  },
  gridItem: {
    flexGrow: '1',
    alignSelf: 'stretch'
  },
  container: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    backgroundColor: '#147b45',
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr',
    gridTemplateRows: '1fr 2fr 1fr',
    alignSelf: 'stretch'
  },
  horizontalOpponentHand: {
    gridColumn: '2 / 3',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  leftVerticalOpponentHand: {
    gridColumn: '1 / 2',
    gridRow: '2 / 3',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    position: 'relative'
  },
  gameField: {
    gridColumn: '2 / 3',
    gridRow: '2 / 3'
  },
  rightVerticalOpponentHand: {
    gridColumn: '3 / 4',
    gridRow: '2 / 3',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    position: 'relative'
  },
  horizontalPlayerHand: {
    gridColumn: '2 / 3',
    gridRow: '3 / 4',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  verticalHandContainer: {
    width: '65%',
    margin: '0 auto'
  },
  topRowHeight: {
    height: '25%'
  },
  middleRowHeight: {
    height: '30%'
  },
  panel: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    height: '100%'
  },
  title: {
    marginBottom: '20px !important',
    marginTop: '20px !important'
  },
  displayName: {
    position: 'absolute'
  },
  verticalDisplayName: {
    position: 'absolute',
    marginTop: '80%',
    marginLeft: '10px'
  },
  owDisplayName: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  sticker: {
    display: 'inline-block',
    padding: '5px',
    border: '3px solid rgba(0,0,0,0)',
    cursor: 'pointer',
    '&:hover': {
      border: '3px solid yellow'
    },
    mateSticker: {
      position: 'absolute'
    },
    opponent1Sticker: {
      position: 'absolute',
      marginTop: '80%',
      marginLeft: '10px'
    },
    opponent2Sticker: {
      position: 'absolute',
      marginTop: '80%',
      marginRight: '10px'
    }
  }
})
