export default (theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: '0 !important',
    marginTop: '0',
    minWidth: '450px'
  },
  inputs: {
    ...theme.flexColumnCenter
  },
  buttons: {
    ...theme.flexColumnCenter
  }
})
