export default () => ({
  container: {
    height: 0,
    width: '95%'
  },

  unSelectable: {
    userSelect: 'none'
  }
})
