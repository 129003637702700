import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { useNavigate, useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import {
  useFirebaseConnect,
  isLoaded,
  isEmpty,
  useFirebase
} from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import LoadingSpinner from 'components/LoadingSpinner'
import Button from '@mui/material/Button'
import { GAME_PATH, LIST_PATH } from 'constants/paths'
import { makeStyles } from '@mui/styles'
import styles from './ProjectData.styles'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import DeleteIcon from '@mui/icons-material/Delete'
import { useNotifications } from 'modules/notification'
import Alert from '@mui/lab/Alert'
import TextField from '@mui/material/TextField'
import Chat from '../../../../modules/chat'
import Grid from '@mui/material/Grid'

const useStyles = makeStyles(styles)

function ProjectData() {
  const { projectId } = useParams()
  const navigate = useNavigate()
  const classes = useStyles()
  const { showError, showSuccess } = useNotifications()
  const firebase = useFirebase()

  const auth = useSelector(({ firebase }) => firebase.auth)
  function startGame() {
    return firebase
      .push(`projects/${projectId}/game/logs/`, {
        message: 'START GAME'
      })
      .then(
        firebase.update(`projects/${projectId}/game`, {
          state: 'START',
          previous_processed_hand: 'NONE'
        })
      )
  }
  const handleChange = (event, playerId) => {
    setTeam(event.target.value, playerId)
  }
  function setTeam(team, playerId) {
    return firebase.update(`projects/${projectId}/players/${playerId}`, {
      team: team
    })
  }
  const handleEndScoreChange = (event) => {
    setEndScore(event.target.value)
  }
  function setEndScore(endScore) {
    return firebase.update(`projects/${projectId}`, {
      endScore: endScore
    })
  }
  function leaveGame(playerId) {
    return firebase
      .remove(`projects/${projectId}/players/${playerId}`)
      .then(() => {
        showSuccess('Left game')
        navigate(`${LIST_PATH}`)
      })
      .catch((err) => {
        console.error('Error:', err) // eslint-disable-line no-console
        showError(err.message || 'Could not delete project')
        return Promise.reject(err)
      })
  }
  function createPlayer(team1) {
    const userID = `COMPUTER${new Date().getUTCMilliseconds()}`
    const updateData = {
      team: team1 ? 2 : 1,
      displayName: `computer_${new Date().getUTCMilliseconds()}`,
      createdAt: firebase.database.ServerValue.TIMESTAMP,
      type: 'COMPUTER'
    }
    return firebase
      .update(`projects/${projectId}/players/${userID}`, updateData)
      .then(() => {
        showSuccess('Joined Game')
      })
      .catch((err) => {
        console.error('Error:', err) // eslint-disable-line no-console
        showError(err.message || 'Could not join Game')
        return Promise.reject(err)
      })
  }

  function deletePlayer(playerId) {
    return firebase
      .remove(`projects/${projectId}/players/${playerId}`)
      .then(() => showSuccess('Player deleted successfully'))
      .catch((err) => {
        console.error('Error:', err) // eslint-disable-line no-console
        showError(err.message || 'Could not delete project')
        return Promise.reject(err)
      })
  }
  // Create listener for projects
  useFirebaseConnect([
    {
      path: `projects/${projectId}`,
      storeAs: 'game'
    }
  ])

  // Get projects from redux state
  const project = useSelector(
    ({
      firebase: {
        data: { game }
      }
    }) => game
  )
  // Show loading spinner while project is loading
  if (!isLoaded(project) || (isLoaded(project) && !project)) {
    return <LoadingSpinner />
  }

  let team1PlayersLength = 0
  let team2PlayersLength = 0
  project &&
    !isEmpty(project.players) &&
    Object.entries(project.players).map((player, ind) => {
      if (player[1].team === 1) {
        team1PlayersLength++
      }

      if (player[1].team === 2) {
        team2PlayersLength++
      }
    })

  let showStartGameButton = true
  let waitToStart = false
  if (typeof project.game !== 'undefined' && project.game.state === 'START') {
    waitToStart = true
    showStartGameButton = false
    setTimeout(function () {
      navigate(`${GAME_PATH}/${projectId}`)
    }, 4500)
  }
  const lobbyFull =
    (project.players ? Object.entries(project.players).length === 4 : false) &&
    typeof project.state === 'undefined' &&
    showStartGameButton
  return (
    <div className={classes.root}>
      <Alert className={classes.alert} severity="info">
        Om een spel te kunnen starten moeten er 4 spelers in de lobby zitten en
        moeten de teams gelijk verdeeld zijn. Door op "+" te drukken kun je een
        computer toevoegen.
      </Alert>
      <div className={classes.top}>
        <h1>{project.name}</h1>
        <div>
          {lobbyFull && team1PlayersLength === 2 && team2PlayersLength === 2 ? (
            <Button
              onClick={() => startGame()}
              variant="contained"
              color="secondary">
              Spel starten
            </Button>
          ) : (
            <Button variant="outlined" color="primary" disabled>
              {waitToStart ? (
                <span>
                  {' '}
                  Spel laden ...{' '}
                  <CircularProgress mode="indeterminate" size={10} />
                </span>
              ) : (
                ' Settings nog niet in orde.'
              )}
            </Button>
          )}
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <div className={classes.card}>
            <Typography component="h2" className={classes.title}>
              <strong>Gamesettings</strong>
              <hr />
            </Typography>
            <form className={classes.form}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(event) => handleEndScoreChange(event)}
                  value={project.endScore}>
                  <MenuItem value={101}>Boom (tot 101)</MenuItem>
                  <MenuItem value={51}>Halve Boom (tot 51)</MenuItem>
                </Select>
              </FormControl>
            </form>
            <Typography component="h2" className={classes.title}>
              <strong>Spelers</strong>
              <hr />
            </Typography>
            {!isEmpty(project.players) &&
            team1PlayersLength !== 2 &&
            team2PlayersLength !== 2 ? (
              <Alert className={classes.alert} severity="warning">
                Teams zijn niet gelijk verdeeld
              </Alert>
            ) : (
              <></>
            )}
            <div className={classes.playerContainer}>
              {!isEmpty(project.players) &&
                Object.entries(project.players).map((player, ind) => {
                  return (
                    <div key={`${player[0]}-${ind}`} className={classes.align}>
                      <FormControl className={classes.formControl}>
                        <TextField value={player[1].displayName} disabled />
                      </FormControl>
                      <FormControl className={classes.formControl}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={player[1].team}
                          onChange={(event) => handleChange(event, player[0])}>
                          <MenuItem value={1}>Team 1</MenuItem>
                          <MenuItem value={2}>Team 2</MenuItem>
                        </Select>
                      </FormControl>
                      {auth.uid !== player[0] ? (
                        <Tooltip title="delete" className={classes.margin}>
                          <IconButton onClick={() => deletePlayer(player[0])}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <></>
                      )}
                      {auth.uid === player[0] ? (
                        <div className={classes.inline}>
                          <Button
                            onClick={() => leaveGame(player[0])}
                            variant="outlined"
                            className={classes.marginRight}>
                            Verlaten
                          </Button>
                          {!isEmpty(project.players) &&
                          Object.keys(project.players).length !== 4 &&
                          !lobbyFull ? (
                            <Button
                              onClick={() =>
                                createPlayer(team1PlayersLength === 2)
                              }
                              variant="outlined"
                              className={classes.marginRight}>
                              +
                            </Button>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )
                })}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <Chat path={`${projectId}/chat`} />
        </Grid>
      </Grid>
    </div>
  )
}

export default ProjectData
