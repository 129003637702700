import { isCardPlayable } from './isCardPlayable'

export function isAnyCardPlayable(
  playerCards,
  opponent1Card,
  mateCard,
  opponent2Card,
  currentHandSuit,
  suitToFollow,
  currentHandTrump
) {
  let isAnyCardPlayable
  let playAbleCardsAmount = 0
  playerCards.map((card) => {
    const isCardPlayableVar = isCardPlayable(
      card.card,
      opponent1Card,
      mateCard,
      opponent2Card,
      currentHandSuit,
      suitToFollow,
      currentHandTrump
    )
    if (isCardPlayableVar) {
      playAbleCardsAmount++
    }
  })
  isAnyCardPlayable = playAbleCardsAmount === 0
  let playAbleCardsAmount02 = 0
  if (isAnyCardPlayable) {
    playerCards.map((card) => {
      const isCardPlayableVar = isCardPlayable(
        card.card,
        opponent1Card,
        mateCard,
        opponent2Card,
        currentHandSuit,
        suitToFollow,
        currentHandTrump,
        true
      )
      if (isCardPlayableVar) {
        playAbleCardsAmount02++
      }
    })
    if (playAbleCardsAmount02 === 0) {
      isAnyCardPlayable = 'justPlayEverything'
    }
  }
  return isAnyCardPlayable
}
