import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import styles from '../../Card/Card.styles'
import CardSvg from '../CardSvg'

const useStyles = makeStyles(styles)

function HorizontalCard({ card, noblur = false }) {
  const classes = useStyles()
  const viewBoxWidth = 244
  const viewBoxHeight = 169
  const paddingBottomPercent = (viewBoxHeight / viewBoxWidth) * 100

  return (
    <div
      className={classes.container}
      style={{ paddingBottom: `${paddingBottomPercent}%` }}>
      <svg
        viewBox={`-${viewBoxWidth} 0 ${viewBoxWidth} ${viewBoxHeight}`}
        className={classes.card}>
        <CardSvg card={card} rotate={90} noblur={noblur} />
      </svg>
    </div>
  )
}

HorizontalCard.propTypes = {
  card: PropTypes.object.isRequired,
  noblur: PropTypes.bool
}

export default HorizontalCard
