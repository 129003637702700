import React from 'react'
import PropTypes from 'prop-types'
import HorizontalHandBack from './transformations/horizontalHandBack'
import VerticalHandBack from './transformations/verticalHandBack'

function HandBack({ vertical = false, ...props }) {
  return vertical ? (
    <VerticalHandBack {...props} />
  ) : (
    <HorizontalHandBack {...props} />
  )
}

HandBack.propTypes = {
  vertical: PropTypes.bool
}

export default HandBack
