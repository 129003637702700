import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import styles from './Navbar.styles'

const useStyles = makeStyles(styles)

function NavbarWithoutAuth({ children, brandPath }) {
  const classes = useStyles()

  return (
    <div>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography
            color="inherit"
            variant="h6"
            component={Link}
            to={brandPath || '/'}
            className={classes.brand}
            data-test="brand">
            <strong>Manillen Online</strong>
          </Typography>
          <div className={classes.flex} />
          {children}
        </Toolbar>
      </AppBar>
    </div>
  )
}

NavbarWithoutAuth.propTypes = {
  children: PropTypes.array,
  brandPath: PropTypes.string
}

export default NavbarWithoutAuth
