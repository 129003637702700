import React from 'react'
import PropTypes from 'prop-types'
import cardSvg from '../../assets/svg-cards.svg'
import { Suits, Ranks } from './constants'
import { makeStyles } from '@mui/styles'
import styles from '../Hand/Hand.styles'
import { isCardPlayable } from '../Game/isCardPlayable'
const useStyles = makeStyles(styles)

function CardSvg({
  x,
  y,
  rotate = 0,
  card,
  pickCard,
  cardKey = 0,
  suitToFollow,
  currentHandSuit,
  currentHandTrump,
  opponent1Card,
  opponent2Card,
  mateCard,
  isAnyCardPlayable,
  noblur
}) {
  const classes = useStyles()
  const svgRank = getSvgCardRank(card.rank)
  const svgSuit = getSvgCardSuit(card.suit)
  const xlinkHref = `${cardSvg}#${svgRank}_${svgSuit}`
  const fillColor = getFillColor(card.suit)
  let cardClass = {}
  if (!noblur) {
    if (
      !isCardPlayable(
        card,
        opponent1Card,
        mateCard,
        opponent2Card,
        currentHandSuit,
        suitToFollow,
        currentHandTrump,
        isAnyCardPlayable
      )
    ) {
      cardClass = { filter: 'url(#blur)' }
    }
  }
  return (
    <use
      className={classes.card}
      style={cardClass}
      x={x}
      y={y}
      xlinkHref={xlinkHref}
      fill={fillColor}
      transform={`rotate(${rotate})`}
      onClick={() => pickCard(card, cardKey)}
    />
  )
}

CardSvg.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  rotate: PropTypes.number,
  card: PropTypes.object.isRequired,
  pickCard: PropTypes.func,
  cardKey: PropTypes.number,
  suitToFollow: PropTypes.string,
  currentHandSuit: PropTypes.string,
  currentHandTrump: PropTypes.string,
  opponent1Card: PropTypes.object,
  opponent2Card: PropTypes.object,
  mateCard: PropTypes.object,
  isAnyCardPlayable: PropTypes.bool,
  noblur: PropTypes.bool
}

export default CardSvg

function getSvgCardSuit(suit) {
  switch (suit) {
    case Suits.Hearts:
      return 'heart'
    case Suits.Spades:
      return 'spade'
    case Suits.Diamonds:
      return 'diamond'
    case Suits.Clubs:
      return 'club'
    default:
      return ''
  }
}

function getSvgCardRank(rank) {
  switch (rank) {
    case Ranks.Ace:
      return '1'
    case Ranks.Two:
      return '2'
    case Ranks.Three:
      return '3'
    case Ranks.Four:
      return '4'
    case Ranks.Five:
      return '5'
    case Ranks.Six:
      return '6'
    case Ranks.Seven:
      return '7'
    case Ranks.Eight:
      return '8'
    case Ranks.Nine:
      return '9'
    case Ranks.Ten:
      return '10'
    case Ranks.Jack:
      return 'jack'
    case Ranks.Queen:
      return 'queen'
    case Ranks.King:
      return 'king'
    default:
      return ''
  }
}

function getFillColor(suit) {
  switch (suit) {
    case Suits.Diamonds:
    case Suits.Hearts:
      return 'red'
    case Suits.Clubs:
    case Suits.Spades:
      return 'black'
    default:
      return ''
  }
}
