import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'react-redux-firebase'
import Paper from '@mui/material/Paper'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { makeStyles } from '@mui/styles'
import styles from './ProjectTile.styles'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(styles)

function ProjectTile({
  name,
  projectId,
  showDelete,
  onJoin,
  players,
  createdAt
}) {
  const classes = useStyles()
  const auth = useSelector(({ firebase: { auth } }) => auth)

  function goToProject() {
    onJoin(projectId, true)
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.top}>
        <span className={classes.name}>
          <strong>{name || 'No Name'}</strong>
        </span>
        {!isEmpty(players) && Object.keys(players).includes(auth.uid) ? (
          <Button
            onClick={() => goToProject()}
            color="primary"
            variant="outlined">
            Terug meespelen
          </Button>
        ) : (
          <></>
        )}

        {!isEmpty(players) &&
        Object.keys(players).length !== 4 &&
        !Object.keys(players).includes(auth.uid) ? (
          <Button
            onClick={() => goToProject()}
            color="primary"
            variant="outlined">
            Meespelen
          </Button>
        ) : (
          <></>
        )}
      </div>
      <div>
        {!isEmpty(players) &&
          Object.entries(players).map((player, ind) => {
            return (
              <Typography
                key={`${player.id}-${ind}`}
                component="div"
                className={classes.displayName}>
                <AccountCircle className={classes.userIcon} />
                <div>{player[1].displayName}</div>
              </Typography>
            )
          })}
      </div>
      <small className={classes.small}>
        Gestart op {new Date(createdAt).toLocaleString()}
      </small>
    </Paper>
  )
}

ProjectTile.propTypes = {
  projectId: PropTypes.string.isRequired,
  showDelete: PropTypes.bool,
  name: PropTypes.string,
  onJoin: PropTypes.func.isRequired,
  players: PropTypes.object.isRequired,
  createdAt: PropTypes.number.isRequired
}

ProjectTile.defaultProps = {
  showDelete: false
}

export default ProjectTile
