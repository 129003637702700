import React from 'react'
import Paper from '@mui/material/Paper'
import { makeStyles } from '@mui/styles'
import AccountEditor from '../AccountEditor/AccountEditor'
import styles from './AccountPage.styles'

const useStyles = makeStyles(styles)

function AccountPage() {
  const classes = useStyles()

  return (
    <Paper className={classes.pane}>
      <h1>Account</h1>
      <AccountEditor />
    </Paper>
  )
}

export default AccountPage
