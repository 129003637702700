import React from 'react'
import PropTypes from 'prop-types'
import HorizontalCard from './transformations/HorizontalCard'
import VerticalCard from './transformations/VerticalCard'

function Card({ horizontal = false, ...props }) {
  return horizontal ? (
    <HorizontalCard {...props} />
  ) : (
    <VerticalCard {...props} />
  )
}

Card.propTypes = {
  horizontal: PropTypes.bool
}

export default Card
